import "./Profile.scss";
import { useEffect, useState } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Tooltip,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { MESSAGE } from "../../../Constants/Messages";

const Profile = (props: any) => {
  const { currentUser } = props;
  const [tooltipUserName, setTooltipUserName] = useState(false);

  const navigate = useNavigate();

  const toggleTooltipUserName = () => {
    setTooltipUserName(!tooltipUserName);
  };

  useEffect(() => {
    if (currentUser === null || currentUser?.data === null) {
      toast.error(MESSAGE.ERROR.accessDenied);
      localStorage.clear();
      navigate("/login");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  const handleLogout = () => {
    localStorage.clear();
    navigate("/login");
  };

  //Dropdown Toggle
  const [isProfileDropdown, setIsProfileDropdown] = useState(false);
  const toggleProfileDropdown = () => {
    setIsProfileDropdown(!isProfileDropdown);
  };
  return (
    <Dropdown isOpen={isProfileDropdown} toggle={toggleProfileDropdown}>
      <DropdownToggle tag="button" type="button" className="profile">
        {currentUser?.firstName && (
          <div className="user-profile-image">
            {currentUser?.firstName[0]}
            {currentUser?.lastName
              ? currentUser?.lastName[0]
              : currentUser?.firstName[1]}
          </div>
        )}
        Profile
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu-end">
        <h6 id="user-name-profile" className="dropdown-header user-name">
          Welcome {currentUser?.firstName}!
        </h6>
        <Tooltip
          placement="bottom"
          isOpen={tooltipUserName}
          autohide={true}
          target={`user-name-profile`}
          toggle={toggleTooltipUserName}
        >
          {currentUser?.firstName + " " + currentUser?.lastName}
        </Tooltip>
        <DropdownItem className="p-0">
          <div onClick={handleLogout} className="dropdown-item">
            <i className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>{" "}
            <span className="align-middle" data-key="t-logout">
              Logout
            </span>
          </div>
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

export default Profile;
