import { create } from "zustand";
import { getLangName } from "../../Common/helper/helper";

const transcriptionStore = (set: any) => ({
  transcriptionVideoList: [],
  selectedResourceId: [],
  selectedResourceInfo: [],
  isLoading: false,
  selectedVideoId: "",
  selectedVideoInfo: {},
  selectedLang: {},
  defaultLang: "",
  rightSubtitleData: {},
  leftSubtitleData: {},
  langList: [
    {
      value: "",
      label: "",
    },
  ],
  listUpdateStatus: false,
  videoPlayerTime: 0,
  videoLoad: false,
  totalCount: 0,
  pageCount: 1,
  subtitleData: [],
  selectedPage: 0,
  skipCount: 0,
  isDataUpdate: false,
  versionTextData: [],
  versionId: "",

  setTranscriptionList: (VideoFileDataList: any[]) => {
    set(() => ({ transcriptionVideoList: VideoFileDataList }));
  },

  setLoading: (loading: boolean) =>
    set((state: any) => ({
      isLoading: loading,
    })),

  setSelectedVideo: (videoId: any) => {
    set((state: any) => ({
      selectedVideoId: videoId,
    }));
    set((state: any) => ({
      selectedVideoInfo:
        state.transcriptionVideoList.filter((_data: any) => {
          return _data._id === videoId;
        })[0] || null,
    }));
  },
  setSelectedVideoInfo: (info: any) => {
    set((state: any) => ({
      selectedVideoInfo: info,
    }));
  },

  setLangList: (list: any[]) => {
    let temp: any[] = [];
    list.slice(1).forEach((_data: any) => {
      temp.push({
        value: _data?.language && _data?.language[0].toUpperCase(),
        label: getLangName(_data?.language[0]),
      });
    });
    set((state: any) => ({
      langList: temp,
    }));
  },

  setSelectedLang: (lang: any) => {
    set((state: any) => ({
      selectedLang: lang,
    }));
  },

  setListUpdateStatus: (status: boolean) => {
    set((state: any) => ({
      listUpdateStatus: status,
    }));
  },

  setVideoPlayerTime: (time: any) => {
    set((state: any) => ({
      videoPlayerTime: time,
    }));
  },

  setDefaultLang: (lang: any) => {
    set((state: any) => ({
      defaultLang: lang,
    }));
  },
  setRightSubtitleData: (data: any) => {
    set((state: any) => ({
      rightSubtitleData: data,
    }));
  },
  setLeftSubtitleData: (data: any) => {
    set((state: any) => ({
      leftSubtitleData: data,
    }));
  },
  setVideoLoad: (value: boolean) => {
    set((state: any) => ({
      videoLoad: value,
    }));
  },
  setSelectedResourceId: (resourceId: any[]) => {
    set(() => ({ selectedResourceId: resourceId }));
  },
  setSelectedResourceInfo: (resourceInfo: any[]) => {
    set(() => ({ selectedResourceInfo: resourceInfo }));
  },
  setTotalCount: (count: number) =>
    set((state: any) => ({
      totalCount: count,
    })),
  setPageCount: (count: number) =>
    set((state: any) => ({
      pageCount: count,
    })),
  setSelectedPage: (page: number) =>
    set((state: any) => ({
      selectedPage: page,
    })),
  setSkipCount: (page: number) =>
    set((state: any) => ({
      skipCount: page,
    })),
  setIsDataUpdate: (status: number) =>
    set((state: any) => ({
      isDataUpdate: status,
    })),
  setSubtitleData: (data: any[]) => {
    set(() => ({ subtitleData: data }));
  },
  setVersionTextData: (data: any[]) => {
    set(() => ({ versionTextData: data }));
  },
  setVersionId: (id: string) => {
    set(() => ({ versionId: id }));
  },
});

const useTranscriptionStore = create(transcriptionStore);

export default useTranscriptionStore;
