import {
  MAX_FILE_NAME_LENGTH,
  LAYOUT_TYPES,
  VIDEO_STATUS,
  allowedExtensions,
} from "../../Constants/Common/Data";
import {
  AddOnLanguageList,
  languageList,
} from "../../Constants/Common/LangListForTrans";
import { LangListForWhisper } from "../../Constants/Common/LangListForWhisper";

export const bytesToMB = (bytes: number, decimalPlaces = 2) => {
  if (bytes) {
    return bytes.toFixed(decimalPlaces) + " MB";
  } else {
    return "";
  }
};

export const shortName = (name: string, length: number) => {
  if (name && name.length > length) {
    return name.substring(0, length) + "...";
  } else {
    return name;
  }
};

export const getLangName = (name: string) => {
  if (name && name != null && name !== undefined && name !== "") {
    const language = languageList.find(
      (item) => item.value === name.toUpperCase()
    );
    if (language) {
      return language.label;
    } else {
      const AddOnLanguage = AddOnLanguageList.find(
        (item) => item.value === name.toUpperCase()
      );
      if (AddOnLanguage) {
        return AddOnLanguage.label;
      } else {
        const whisperLang = LangListForWhisper.find(
          (item) => item.value === name.toLowerCase()
        );
        if (whisperLang) {
          return whisperLang.label.toLowerCase();
        } else {
          return name;
        }
      }
    }
  } else return name;
};

export const hasFieldPermission = (
  permissions: any[],
  module: any,
  path: any,
  fields: { [k: string]: any } = {}
) => {
  const result = permissions.filter(
    (permission) => permission.modelName === module && permission.url === path
  );
  if (result && result.length > 0) {
    result[0].fields.forEach((element: any) => {
      fields[element.field] = element;
    });
  }
  return fields;
};

export const hasApiPermission = (permissions: any[], module: any) => {
  const result = permissions.filter(
    (permission) => permission.modelName === module
  );
  if (result && result.length > 0) {
    return true;
  } else {
    return false;
  }
};

export const secondsToMinutesAndHours = (seconds: number) => {
  var hours = Math.floor(seconds / 3600);
  var minutes = Math.floor((seconds % 3600) / 60);
  var remainingSeconds = seconds % 60;

  // Add leading zeros if needed
  var formattedHours = (hours < 10 ? "0" : "") + hours;
  var formattedMinutes = (minutes < 10 ? "0" : "") + minutes;
  var formattedSeconds =
    (remainingSeconds < 10 ? "0" : "") + remainingSeconds.toFixed(0);

  return formattedHours + ":" + formattedMinutes + ":" + formattedSeconds;
};

export const minToSeconds = (endTimeString: string) => {
  const [hours, minutes, seconds] = endTimeString.split(":").map(Number);

  const totalSeconds = hours * 3600 + minutes * 60 + seconds;

  return totalSeconds;
};

export const RedirectToHome = () => {
  localStorage.clear();
  window.location.href = "/login";
};

export const checkSupportedLang = (lang: string) => {
  const language = languageList.find(
    (item) => item.value === lang.toUpperCase()
  );
  if (language) {
    return true;
  } else {
    return false;
  }
};

export const convertDropDownDataToArray = (arrayData: []) => {
  let temp: any[] = [];
  arrayData.forEach((_data: any) => {
    temp.push(_data?.value);
  });
  return temp;
};

export const createVersionArray = (ver: number) => {
  const versionList: any = [{ value: "default", label: "Original" }];

  for (let i = 0; i < ver; i++) {
    versionList.push({
      value: i,
      label: `Version ${i + 1}`,
    });
  }
  return versionList;
};

export const secondsToVTTTime = (seconds: number) => {
  const h = Math.floor(seconds / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = (seconds % 60).toFixed(3);
  return `${h.toString().padStart(2, "0")}:${m
    .toString()
    .padStart(2, "0")}:${s.padStart(6, "0")}`;
};

export const jsonToVTT = (jsonData: any) => {
  const vttLines = ["WEBVTT", ""];

  if (jsonData.length > 0) {
    for (const segment of jsonData) {
      const start = secondsToVTTTime(segment.start);
      const end = secondsToVTTTime(segment.end);
      const text = segment.text;

      vttLines.push(`${start} --> ${end}`);
      vttLines.push(text, "");
    }

    return vttLines.join("\n");
  } else {
    return vttLines.join("\n");
  }
};

export const getFlagByLang = (langName: string) => {
  if (langName) {
    const languageEntry = languageList.find(
      (lang) => lang.label.toLowerCase() === langName.toLowerCase()
    );

    if (languageEntry) {
      return languageEntry.value.toLowerCase();
    } else {
      return "";
    }
  } else {
    return "";
  }
};

export const getLangCode = (name: string) => {
  if (name && name != null && name !== undefined && name !== "") {
    const language = languageList.find(
      (item) => item.label.toUpperCase() === name.toUpperCase()
    );
    if (language) {
      return language.value;
    } else {
      const AddOnLanguage = AddOnLanguageList.find(
        (item) => item.label.toUpperCase() === name.toUpperCase()
      );
      if (AddOnLanguage) {
        return AddOnLanguage.value;
      } else {
        return name;
      }
    }
  } else return name;
};

export const getVideoStatus = (status: number) => {
  switch (status) {
    case VIDEO_STATUS.COMPLETED:
      return "COMPLETED";
    case VIDEO_STATUS.NOT_PROCESSED:
      return "FAILED";
    case VIDEO_STATUS.PROCESSING:
      return "PROCESSING";
    case VIDEO_STATUS.UPLOADED:
      return "UPLOADING";
    case VIDEO_STATUS.NOT_TRANSCRIBED:
      return "FAILED";
    case VIDEO_STATUS.REPROCESS_VIDEO:
      return "PROCESSING";
    case VIDEO_STATUS.REPROCESS_VIDEO_COMPLETED:
      return "COMPLETED";
    case VIDEO_STATUS.REPROCESS_VIDEO_ERROR:
      return "FAILED";
    default:
      return "NOT AVAILABLE";
  }
};

export const addSubtitle = (subtitleData: any[]) => {
  let subTitle: any = [];

  if (subtitleData && subtitleData.length > 0) {
    subtitleData.forEach((_data, index) => {
      subTitle.push({
        src: `data:text/vtt;base64,${btoa(
          unescape(encodeURIComponent(jsonToVTT(_data?.segments || [])))
        )}`,
        kind: "subtitles",
        srclang: _data?.language,
        label: getLangName(_data?.language[0]),
        default: index === 0 ? true : false,
      });
    });
    return subTitle;
  } else {
    return [];
  }
};

export const isAllowedExtension = (filename: any) => {
  const fileExtension = filename.split(".").pop().toLowerCase();

  return allowedExtensions.includes(fileExtension);
};

export const checkFileNameLength = (filename: any) => {
  const onlyFileName = filename.split(".")[0];
  if (onlyFileName.length <= MAX_FILE_NAME_LENGTH) {
    return true;
  } else {
    return false;
  }
};

export const handleDownload = (textToDownload: string) => {
  const blob = new Blob([textToDownload], { type: "text/plain" });
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = "subtitle.txt";
  document.body.appendChild(a);
  a.click();
  URL.revokeObjectURL(url);
  document.body.removeChild(a);
};

export const getExtension = (filename: any) => {
  return filename.split(".").pop().toLowerCase();
};

export const getFileNameWithoutExtension = (filename: any) => {
  const tempName = filename.split(".");
  return tempName.slice(0, tempName.length - 1).join(".");
};

export const getValueFromLabel = (label: number) => {
  const layout = Object.values(LAYOUT_TYPES).find(
    (layout) => layout.label === label
  );
  return layout ? layout.value : null;
};

export const convertArrayToString = (data: any[]) => {
  if (data.length > 2) {
    var transformedValues = data
      .slice(2)
      .map((transformValue) => getLangName(transformValue));
    var myString = transformedValues.join(", ");
    return myString;
  } else return;
};

export const isBlank = (obj: Object) => {
  if (obj === undefined || obj === null) {
    return true;
  }
  if (typeof obj === "string") {
    return obj.trim() === "";
  }
  if (Array.isArray(obj)) {
    return obj.length === 0;
  }
  if (typeof obj === "object") {
    return Object.keys(obj).length === 0;
  }
  return false;
};

export const secondsToMinutes = (seconds: number) => {
  var hours = Math.floor(seconds / 3600);
  var minutes = Math.floor((seconds % 3600) / 60);
  var remainingSeconds = seconds % 60;

  // Add leading zeros if needed
  var formattedHours = (hours < 10 ? "0" : "") + hours;
  var formattedMinutes = (minutes < 10 ? "0" : "") + minutes;
  var formattedSeconds =
    (remainingSeconds < 10 ? "0" : "") + remainingSeconds.toFixed(0);
  if (hours === 0 && minutes === 0 && remainingSeconds >= 0) {
    return formattedSeconds + " sec";
  } else if (hours === 0 && minutes >= 0) {
    return formattedMinutes + " min " + formattedSeconds + " sec";
  } else if (hours >= 0 && minutes >= 0) {
    return (
      formattedHours +
      " hours " +
      formattedMinutes +
      " min " +
      formattedSeconds +
      " sec"
    );
  }
};

export const getWhisperLangName = (name: string) => {
  if (name && name != null && name !== undefined && name !== "") {
    const language = LangListForWhisper.find(
      (item) => item.value === name.toLowerCase()
    );
    if (language) {
      return language.label;
    }
  } else return name;
};
