import { useEffect, useState } from "react";
import "./Download.scss";
import useFileDownloadStore from "../../../../Store/DownloadStore/DownloadStore";
import { toast } from "react-hot-toast";
import JSZip from "jszip";
import DownloadIcon from "../../../../assets/images/New/downlaod-animated.gif";
import { PopoverBody, Progress, UncontrolledPopover } from "reactstrap";
import { bytesToMB } from "../../../../Common/helper/helper";
import axios from "axios";
import { MESSAGE } from "../../../../Constants/Messages";

const Download = () => {
  const [showDownloadSection, setShowDownloadSection] = useState(false);
  const [downloadPercentage, setDownloadPercentage] = useState([0]);

  const {
    removeFileFromDownload,
    setDownloadType,
    downloadFiles,
    downloadType,
  } = useFileDownloadStore();

  useEffect(() => {
    if (downloadFiles && downloadType && downloadFiles.length > 0) {
      setShowDownloadSection(true);
      startDownloading();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downloadFiles, downloadType]);

  const startDownloading = async () => {
    await download();
  };

  const download = async () => {
    // const source = axios.CancelToken.source();
    try {
      const zip = new JSZip();

      if (downloadFiles.length === 1 && downloadType) {
        const videoInfo = downloadFiles[0];
        const response: any = await axios({
          url: videoInfo[downloadType],
          method: "GET",
          responseType: "blob",
          onDownloadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / (progressEvent.total || 100)
            );
            setDownloadPercentage([percentCompleted]);
          },
          // cancelToken: source.token,
        });

        const blob = new Blob([response]);
        const tempUrl = URL.createObjectURL(blob);
        const aTag = document.createElement("a");
        aTag.href = tempUrl;
        const downloadValue = videoInfo[downloadType] as string;
        if (downloadValue) {
          // eslint-disable-next-line no-useless-escape
          aTag.download = downloadValue.replace(/^.*[\\\/]/, "");
          document.body.appendChild(aTag);
          aTag.click();
        }
        URL.revokeObjectURL(tempUrl);
        aTag.remove();
      } else {
        // Multiple files download as a zip archive
        if (downloadType) {
          await Promise.all(
            downloadFiles.map(async (videoInfo: any, index: number) => {
              try {
                const response: any = await axios({
                  url: videoInfo[downloadType],
                  method: "GET",
                  responseType: "blob",
                  onDownloadProgress: (progressEvent) => {
                    const percentCompleted = Math.round(
                      (progressEvent.loaded * 100) /
                        (progressEvent.total || 100)
                    );
                    updateDownloadPercentage(index, percentCompleted);
                  },
                  // cancelToken: source.token,
                }).catch((err: any) => {
                  toast.error(
                    "Failed to download file " +
                      videoInfo?.title +
                      "due to " +
                      err.message.message
                  );
                });

                const blob = new Blob([response]);

                if (videoInfo[downloadType]) {
                  const filename = videoInfo[downloadType].replace(
                    // eslint-disable-next-line no-useless-escape
                    /^.*[\\\/]/,
                    ""
                  );

                  let uniqueFilename = filename;
                  let counter = 1;
                  while (zip.files[uniqueFilename]) {
                    uniqueFilename = `${counter}_${filename}`;
                    counter++;
                  }

                  zip.file(uniqueFilename, blob);
                }
              } catch (error) {
                // source.cancel("Download failed");
                console.error(
                  `Failed to download file (${downloadType}):`,
                  error
                );
                // throw error;
              }
            })
          );

          const content = await zip.generateAsync({ type: "blob" });
          const tempUrl = URL.createObjectURL(content);
          const aTag = document.createElement("a");
          aTag.href = tempUrl;
          aTag.download = "files.zip";
          document.body.appendChild(aTag);
          aTag.click();
          URL.revokeObjectURL(tempUrl);
          aTag.remove();
        }
      }
      removeFileFromDownload([]);
      setDownloadType("");
      setShowDownloadSection(false);
    } catch (error) {
      console.error("Failed to download files", error);
      // source.cancel("Download failed");
      removeFileFromDownload([]);
      setDownloadType("");
      setShowDownloadSection(false);
      toast.error(MESSAGE.ERROR.downloadFail);
    }
  };

  const updateDownloadPercentage = (
    index: number,
    percentCompleted: number
  ) => {
    setDownloadPercentage((prevDownload: any[]) => {
      const newDownloadPercentage = [...prevDownload];
      newDownloadPercentage[index] = percentCompleted;
      return newDownloadPercentage;
    });
  };

  return (
    <>
      {showDownloadSection && (
        <>
          <div className="download-header cursor-pointer">
            <img id="download-header" src={DownloadIcon} alt="download" />
            <UncontrolledPopover target="download-header" placement="bottom">
              <PopoverBody className="download-pop-over">
                <div className="download-pop-over-section">
                  {downloadFiles.map((_data: any, index: number) => {
                    return (
                      <>
                        <div className="mb-2 mt-1" key={index}>
                          {_data?.title}{" "}
                          {_data?.size && `(${bytesToMB(_data?.size)})`}
                        </div>

                        <Progress
                          color="primary"
                          striped
                          className="progress-xl"
                          value={downloadPercentage[index]}
                        >
                          {" "}
                          {downloadPercentage[index]} %{" "}
                        </Progress>
                      </>
                    );
                  })}
                </div>
              </PopoverBody>
            </UncontrolledPopover>
          </div>
        </>
      )}
    </>
  );
};

export default Download;
