import DefaultSection from "../Components/New/DefaultSection/DefaultSection";
import FileSection from "../Components/New/FileSection/FileSection";
import ResizeHandle from "../Components/New/ResizeHandler/ResizeHandler";
import SubtitleSection from "../Components/New/SubtitleSection/SubtitleSection";
import VideoPlayerSection from "../Components/New/VideoPlayerSection/VideoPlayerSection";
import WaveSection from "../Components/New/WaveSection/WaveSection";
import { TAB_TYPE, TAB_TYPE_DATA } from "../Constants/Common/Data";
import useTabStore from "../Store/TabStore/TabStore";
import { Panel, PanelGroup } from "react-resizable-panels";
import useTranscriptionStore from "../Store/TranscriptionStore/TranscriptionStore";
import SpinnerLoader from "../Components/Common/Spinner/Spinner";
import { useEffect } from "react";
import UploadSection from "../Components/New/UploadSection/UploadSection";
import useUserStore from "../Store/UserStore/UserStore";
import { getValueFromLabel } from "../Common/helper/helper";
import { getVideoListApi } from "../Common/helper/videohelper";
import { toast } from "react-hot-toast";
import { MESSAGE } from "../Constants/Messages";

const NewDashboard = () => {
  const { activeItems, toggleActiveItem, setActiveItem } = useTabStore(
    (state: any) => ({
      activeItems: state.activeItems,
      toggleActiveItem: state.toggleActiveItem,
      setActiveItem: state.setActiveItem,
    })
  );

  const { isLoading, setLoading, transcriptionVideoList } =
    useTranscriptionStore((state: any) => ({
      isLoading: state.isLoading,
      setLoading: state.setLoading,
      transcriptionVideoList: state.transcriptionVideoList,
    }));

  const { currentUser } = useUserStore((state: any) => ({
    currentUser: state.currentUser,
    layout: state.layout,
  }));

  const isActive = (item: string) => activeItems.includes(item);

  const allowDrop = (ev: any) => {
    ev.preventDefault();
  };

  const drop = (ev: any) => {
    ev.preventDefault();
    var data = ev.dataTransfer.getData("text");
    if (
      data !== TAB_TYPE.VIDEO &&
      data !== null &&
      data !== undefined &&
      data !== "" &&
      TAB_TYPE_DATA.includes(data)
    ) {
      toggleActiveItem(data);
    }
    if (!activeItems.includes(TAB_TYPE.VIDEO) && data === TAB_TYPE.WAVE) {
      toggleActiveItem(TAB_TYPE.VIDEO);
    }
    if (data === TAB_TYPE.VIDEO) {
      if (transcriptionVideoList && transcriptionVideoList.length > 0) {
        toggleActiveItem(TAB_TYPE.VIDEO);
      } else {
        toast(MESSAGE.WARNING.uploadVideo);
      }
    }
  };

  useEffect(() => {
    const tabSetting = currentUser?.userDefaultSetting?.tabSetting;
    if (currentUser && tabSetting) {
      const values = getValueFromLabel(tabSetting);
      setActiveItem(values);
      if (
        values?.includes(TAB_TYPE.WAVE) &&
        currentUser?.configSetting &&
        currentUser?.configSetting?.isWaveformAllow
      ) {
        setActiveItem(values);
      } else {
        const tempValue = values?.filter(
          (value: string) => value !== TAB_TYPE.WAVE
        );
        setActiveItem(tempValue);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  useEffect(() => {
    callApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const callApi = async () => {
    setLoading(true);
    await getList();
    setLoading(false);
  };

  const getList = async () => {
    const storeMethods = useTranscriptionStore.getState();
    await getVideoListApi(storeMethods, toast, true);
  };

  return (
    <>
      {isLoading ? <SpinnerLoader /> : ""}
      <div
        className="new-page-content"
        id="new-page-content"
        onDrop={(event) => drop(event)}
        onDragOver={(event) => allowDrop(event)}
      >
        <div className="h-100">
          <div className="layout">
            <div
              className={`main-section ${
                activeItems.includes(TAB_TYPE.WAVE) ? "h-80" : ""
              }`}
            >
              {activeItems && activeItems.length === 0 && <DefaultSection />}
              {activeItems && activeItems.length > 0 && (
                <>
                  <PanelGroup autoSaveId="main-section" direction="horizontal">
                    {isActive(TAB_TYPE.UPLOAD) && (
                      <>
                        <Panel
                          collapsible={false}
                          defaultSize={50}
                          order={1}
                          id="1"
                          minSize={29}
                        >
                          <UploadSection />
                        </Panel>
                        <ResizeHandle />
                      </>
                    )}
                    {isActive(TAB_TYPE.FILE) && (
                      <>
                        <Panel
                          collapsible={false}
                          defaultSize={50}
                          order={2}
                          id="2"
                          minSize={27}
                        >
                          <FileSection />
                        </Panel>
                        <ResizeHandle />
                      </>
                    )}
                    {isActive(TAB_TYPE.SUBTITLE) && (
                      <>
                        <Panel
                          collapsible={false}
                          defaultSize={50}
                          order={3}
                          id="3"
                          minSize={27}
                        >
                          <SubtitleSection />
                        </Panel>
                        <ResizeHandle />
                      </>
                    )}
                    {isActive(TAB_TYPE.VIDEO) && (
                      <>
                        <Panel
                          collapsible={false}
                          defaultSize={50}
                          order={4}
                          id="4"
                          minSize={27}
                        >
                          <VideoPlayerSection />
                        </Panel>
                        <ResizeHandle />
                      </>
                    )}
                    <Panel
                      collapsible={false}
                      defaultSize={1}
                      order={5}
                      id="5"
                      minSize={0}
                    >
                      <div></div>
                    </Panel>
                  </PanelGroup>
                </>
              )}
            </div>
            {isActive(TAB_TYPE.WAVE) && (
              <div className="footer-wave">
                <WaveSection />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default NewDashboard;
