export const VideoInfoDefaultValue = {
  _id: "",
  title: "",
  date: "",
  size: "",
  user: "",
  status: 0,
  languages: [],
};

export const VideoFileUploadDetailsDefaultValue = {
  name: "",
  size: "",
  type: "",
  user: "",
  langList: [],
};

export const VideoTextTypeDefaultValue = {
  audioResourceId: "",
  createdAt: "",
  createdBy: "",
  isArchived: false,
  language: "",
  productionResourceId: "",
  segments: [],
  text: "",
  versions: [],
  updatedAt: "",
  updatedBy: "",
  _id: "",
};

export const VideoSegmentsTypeDefaultValue = {
  avg_logprob: 0,
  compression_ratio: 0,
  end: 0,
  id: 0,
  no_speech_prob: 0,
  seek: 0,
  start: 0,
  temperature: 0,
  text: "",
  tokens: [],
};

export const uploadVideoDetailsDefaultValues = {
  id: null,
  uploadDetail: { time: 0, date: "", uploadedBy: "", percentage: 0 },
  compressedDetail: {
    time: 0,
    date: "",
    format: "",
    percentage: 0,
    originalFileType: "",
    compressedFileType: "",
  },
  transcriptionDetail: {
    status: 0,
    time: 0,
    date: "",
    autoDetectLanguage: "",
    reprocessed: "",
    percentage: 0,
    reprocessedLang: null,
    reprocessedCreatedAt: null,
  },
};
