import "./SubtitleSection.scss";
import SubtitleIcon from "../../../assets/images/New/text.svg";
import CloseIcon from "../../../assets/images/New/close.svg";
import AddIcon from "../../../assets/images/New/Add.svg";
import EditIcon from "../../../assets/images/New/Edit.svg";
import DeleteIcon from "../../../assets/images/New/Delete.svg";
import CheckCircleIcon from "../../../assets/images/New/CheckCircle.svg";
import RightIcon from "../../../assets/images/New/Right.svg";
import { TAB_TYPE, VIDEO_STATUS } from "../../../Constants/Common/Data";
import useTabStore from "../../../Store/TabStore/TabStore";
import Select from "react-select";
import { useEffect, useState } from "react";
import useTranscriptionStore from "../../../Store/TranscriptionStore/TranscriptionStore";
import {
  convertArrayToString,
  createVersionArray,
  getFlagByLang,
  getLangName,
  handleDownload,
  secondsToMinutesAndHours,
} from "../../../Common/helper/helper";
import {
  PostReviewTextPayload,
  ReviewInfoDefaultType,
  VideoTextType,
  textType,
} from "../../../Common/interface/types";
import {
  postFetchTextDetails,
  postUpdateSubTittleText,
  postReviewText,
} from "../../../ApiService/ApiCall";
import { toast } from "react-hot-toast";
import { VideoSegmentsTypeDefaultValue } from "../../../Common/interface/defaultValue";
import RightSection from "./RightSection";
import NotAvailable from "../Common/NotAvailable";
import DeleteModal from "../Common/DeleteModal";
import ManualTransSection from "./ManualTransSection/ManualTransSection";
import CopyIcon from "../../../assets/images/New/Copy.svg";
import DownloadIcon from "../../../assets/images/New/Download.svg";
import useVideoRefStore from "../../../Store/VideoStore/VideoStore";
import VerifyIcon from "../../../assets/images/New/verifyIcon.png";
import useUserStore from "../../../Store/UserStore/UserStore";
import { PopoverBody, Tooltip, UncontrolledPopover } from "reactstrap";
import { MESSAGE } from "../../../Constants/Messages";

const SubtitleSection = () => {
  const { toggleActiveItem, activeItems, inActiveItem } = useTabStore(
    (state: any) => ({
      toggleActiveItem: state.toggleActiveItem,
      activeItems: state.activeItems,
      inActiveItem: state.inActiveItem,
    })
  );

  const {
    selectedVideoId,
    setLoading,
    videoPlayerTime,
    defaultLang,
    leftSubtitleData,
    selectedVideoInfo,
    setLeftSubtitleData,
    setVideoLoad,
    setDefaultLang,
    setSelectedVideoInfo,
    setIsDataUpdate,
    isDataUpdate,
    setVersionTextData,
    setVersionId,
    // versionId,
  } = useTranscriptionStore((state: any) => ({
    selectedVideoId: state.selectedVideoId,
    setLoading: state.setLoading,
    videoPlayerTime: state.videoPlayerTime,
    defaultLang: state.defaultLang,
    leftSubtitleData: state.leftSubtitleData,
    selectedVideoInfo: state.selectedVideoInfo,
    setLeftSubtitleData: state.setLeftSubtitleData,
    setVideoLoad: state.setVideoLoad,
    setDefaultLang: state.setDefaultLang,
    setSelectedVideoInfo: state.setSelectedVideoInfo,
    setIsDataUpdate: state.setIsDataUpdate,
    isDataUpdate: state.isDataUpdate,
    setVersionTextData: state.setVersionTextData,
    setVersionId: state.setVersionId,
    // versionId: state.versionId,
  }));

  const { currentUser } = useUserStore((state: any) => ({
    currentUser: state.currentUser,
  }));

  const { playerRef } = useVideoRefStore((state: any) => ({
    playerRef: state.playerRef,
  }));

  const [selectedVersion, setSelectedVersion] = useState<any>();
  const [rightSection, setRightSection] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [versionData, setVersionData] = useState<any>([]);
  const [textResponse, setTextResponse] = useState<textType[]>([]);
  const [updatedTextResponse, setUpdatedTextResponse] = useState<textType[]>(
    []
  );
  const [dataStatus, setDataStatus] = useState(false);
  const [selectedSubtitle, setSelectedSubtitle] = useState<textType>(
    VideoSegmentsTypeDefaultValue
  );
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [msg, setMsg] = useState(MESSAGE.CONTENT.translationNotAvailable);
  const [showManualTransSection, setShowManualTransSection] = useState(false);
  const [showManualTransBtn, setShowManualTransBtn] = useState(false);
  const [textToCopy, setTextToCopy] = useState("");
  const [textToDownload, setTextToDownload] = useState("");
  const [reviewStatus, setReviewStatus] = useState(false);
  const [reviewInfo, setReviewInfo] = useState<ReviewInfoDefaultType>({
    firstName: "",
    lastName: "",
  });
  const [isVersionView, setIsVersionView] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [tooltipOpenForOther, setTooltipOpenForOther] = useState(false);
  const [, setSummaryBtnStatus] = useState(false);
  const [, setSummaryText] = useState("");

  const getActiveDiv = () => {
    const elementList: any = document.querySelectorAll(".subtitle-text");
    for (const element of elementList) {
      if (element.classList.contains("active-text")) {
        const containerLeft: any = document.getElementById(
          "left-text-container"
        );
        const containerRight: any = document.getElementById(
          "right-text-container"
        );
        containerLeft.scrollTop = element.offsetTop - containerLeft.offsetTop;
        if (containerRight) {
          containerRight.scrollTop =
            element.offsetTop - containerRight.offsetTop;
        }
        break;
      }
    }
  };

  useEffect(() => {
    getActiveDiv();
  }, [videoPlayerTime, selectedVideoId]);

  useEffect(() => {
    if (isDataUpdate) {
      setIsDataUpdate(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedVideoId, selectedVideoInfo]);

  useEffect(() => {
    if (!isDataUpdate) {
      if (
        leftSubtitleData &&
        leftSubtitleData?.language &&
        leftSubtitleData?.segments &&
        leftSubtitleData?.segments.length > 0
      ) {
        setDefaultLang(leftSubtitleData?.language[0]);
        setSegmentData(leftSubtitleData?.segments);
        setReviewStatus(leftSubtitleData?.isReviewed);
        setReviewInfo(leftSubtitleData?.reviewedBy);
        setUpdatedTextResponse(leftSubtitleData?.segments);
        createVersion(leftSubtitleData?.versions.length || 0);
        setDataStatus(true);
        setShowManualTransSection(false);
        if (
          leftSubtitleData?.versions &&
          leftSubtitleData?.versions.length > 0 &&
          !selectedVersion
        ) {
          setIsVersionView(true);
        }
        if (leftSubtitleData?.summary) {
          setSummaryText(leftSubtitleData?.summary);
        } else {
          setSummaryText("");
        }
      } else if (
        selectedVideoInfo &&
        (selectedVideoInfo?.status === VIDEO_STATUS.UPLOADED ||
          selectedVideoInfo?.status === VIDEO_STATUS.PROCESSING ||
          selectedVideoInfo?.status === VIDEO_STATUS.REPROCESS_VIDEO)
      ) {
        setMsg(MESSAGE.CONTENT.notProcessed);
        setDataStatus(false);
        setShowManualTransBtn(false);
        setLeftSubtitleData("");
      } else if (
        selectedVideoInfo &&
        (selectedVideoInfo?.status === VIDEO_STATUS.NOT_TRANSCRIBED ||
          selectedVideoInfo?.status === VIDEO_STATUS.NOT_PROCESSED ||
          selectedVideoInfo?.status === VIDEO_STATUS.COMPLETED)
      ) {
        setMsg(MESSAGE.CONTENT.translationAddManual);
        setDataStatus(false);
        setShowManualTransBtn(true);
        setLeftSubtitleData("");
      } else {
        setDataStatus(false);
        setLeftSubtitleData("");
        setTextResponse([]);
      }
      setIsDataUpdate(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedVideoId, leftSubtitleData, selectedVideoInfo]);

  const setSegmentData = (data: any[]) => {
    let tempData: any[] = [];
    data.forEach((_item) => {
      tempData.push({ ..._item });
    });
    const text = tempData
      ?.map((segment) => {
        const { text } = segment;
        return `${text}\n`;
      })
      .join("\n");

    const formattedText = tempData
      ?.map((segment: { end: number; start: number; text: any }) => {
        const startTime = secondsToMinutesAndHours(segment.start);
        const endTime = secondsToMinutesAndHours(segment.end);
        return `IN ${startTime} - OUT ${endTime}\n${segment.text}\n`;
      })
      .join("\n");

    setTextResponse(tempData);
    setTextToCopy(text);
    setTextToDownload(formattedText);
  };

  const createVersion = (ver: number) => {
    if (ver > 0) {
      const verArr = createVersionArray(ver);
      setVersionData(verArr);
      if (!selectedVersion) {
        setSelectedVersion(verArr[0]);
      } else {
        setVersionTextData(
          leftSubtitleData?.versions[selectedVersion.value]?.versionTexts
        );
      }
    } else {
      setVersionData([]);
      setSelectedVersion("");
    }
  };

  const handleTextareaChange = (index: number, newText: any) => {
    const updatedTextResponseCopy: any = [...updatedTextResponse];
    updatedTextResponseCopy[index] = {
      ...updatedTextResponseCopy[index],
      text: newText,
    };
    setUpdatedTextResponse(updatedTextResponseCopy);
    setTextResponse(updatedTextResponseCopy);
  };

  const handleSubmit = () => {
    setIsEdit(false);
    setIsVersionView(false);
    setReviewStatus(false);
    callTextUpdateApi();
  };

  const callTextUpdateApi = async () => {
    try {
      setLoading(true);
      const res: any = await postUpdateSubTittleText({
        id: leftSubtitleData?._id,
        segments: updatedTextResponse,
      });
      setIsEdit(false);
      setIsVersionView(false);
      toast.success(MESSAGE.SUCCESS.documentUpdated);
      AddVersion(res?.result);
      setLoading(false);
    } catch (err: any) {
      console.log(err);
      setLoading(false);
      toast.error(err?.msg || "something went wrong");
    }
  };

  const AddVersion = (res: any) => {
    const versionData = createVersionArray(res?.version);
    setVersionData(versionData);
    setSelectedVersion(versionData[versionData.length - 1]);
    leftSubtitleData.versions.push(res);
    setVersionId(res?._id);
  };

  const handleVersion = (selectedValue: any) => {
    setSelectedVersion(selectedValue);
    if (selectedValue.value === "default") {
      setSegmentData(leftSubtitleData?.segments);
      setReviewStatus(leftSubtitleData?.isReviewed);
      setReviewInfo(leftSubtitleData?.reviewedBy);
      setIsVersionView(true);
      setSummaryBtnStatus(false);
      if (leftSubtitleData?.summary) {
        setSummaryText(leftSubtitleData?.summary);
      } else {
        setSummaryText("");
      }
      setVersionId("");
      setVersionTextData([]);
    } else {
      setSegmentData(leftSubtitleData?.versions[selectedValue.value]?.segments);
      setReviewStatus(
        leftSubtitleData?.versions[selectedValue.value]?.isReviewed
      );
      setReviewInfo(
        leftSubtitleData?.versions[selectedValue.value]?.reviewedBy
      );
      setIsVersionView(false);
      setVersionId(leftSubtitleData?.versions[selectedValue.value]?._id);
      setSummaryBtnStatus(false);
      if (leftSubtitleData?.versions[selectedValue.value].summary) {
        setSummaryText(
          leftSubtitleData?.versions[selectedValue.value]?.summary
        );
      } else {
        setSummaryText("");
      }

      const selectedVersionIndex = selectedValue.value;
      const versionTexts =
        leftSubtitleData?.versions[selectedVersionIndex]?.versionTexts;

      if (versionTexts && versionTexts.length > 0) {
        setVersionTextData(versionTexts);
      } else {
        setVersionTextData([]);
      }
    }
  };

  useEffect(() => {
    if (activeItems.includes(TAB_TYPE.UPLOAD)) {
      setRightSection(false);
    }
  }, [activeItems]);

  const toggleRightSection = () => {
    setRightSection(!rightSection);
    inActiveItem(TAB_TYPE.UPLOAD);
  };
  const handleDeleteSubtitleModal = (subtitleInfo: any) => {
    setSelectedSubtitle(subtitleInfo);
    setShowDeleteModal(true);
  };

  const handleDeleteSubtitle = async () => {
    try {
      setLoading(true);
      const isLastSubtitle = textResponse && textResponse.length === 1;

      if (isLastSubtitle) {
        toast(MESSAGE.WARNING.lastSubtitleDelete);
        setLoading(false);
        setShowDeleteModal(false);
        return;
      }
      const updatedSegments = textResponse.filter(
        (segment: any) => selectedSubtitle.id !== segment?.id
      );
      setTextResponse([...updatedSegments]);
      setUpdatedTextResponse([...updatedSegments]);
      toast.success(
        `${MESSAGE.SUCCESS.transcriptionDeleted} ${selectedSubtitle.start}`
      );
      if (leftSubtitleData.segments.length === 0) {
        setMsg("No subtitles available for the selected video.");
      }
      setShowDeleteModal(false);
      setLoading(false);
    } catch (err: any) {
      toast.error(err.msg || "Something went wrong");
      console.error(err);
      setShowDeleteModal(false);
      setLoading(false);
    }
  };

  const getTextDetailsApi = async (lang: string) => {
    try {
      let res = await postFetchTextDetails({
        productionResourceId: selectedVideoId,
        languages: [lang],
      });
      return res;
    } catch (err: any) {
      toast.error(err.msg);
      console.log(err);
    }
  };

  const onAddSubtitle = async (lang: string) => {
    setLoading(true);
    setVideoLoad(false);
    let res: VideoTextType | any = await getTextDetailsApi(lang);
    if (res && res[0]) {
      setLeftSubtitleData(res[0]);
      setDefaultLang(lang);
      setSegmentData(res[0]?.segments);
      setUpdatedTextResponse(res[0]?.segments);
      createVersion(res[0]?.versions.length || 0);
      setDataStatus(true);
      setShowManualTransSection(false);
      const updateInfo = {
        ...selectedVideoInfo,
        status: VIDEO_STATUS.COMPLETED,
      };
      setSelectedVideoInfo(updateInfo);
    }
    setVideoLoad(true);
    setLoading(false);
  };

  const handleCopy = () => {
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => toast.success(MESSAGE.SUCCESS.textCopied))
      .catch((err) => toast.error("Failed to copy"));
  };

  const handleDownloadClick = () => {
    try {
      handleDownload(textToDownload);
      toast.success(`Document in ${getLangName(defaultLang)} downloaded : TXT`);
    } catch (error) {
      toast.error(
        `Download error: Please check the error : ${error} and retry`
      );
    }
  };

  const handleTextBoxClick = (startTime: number) => {
    if (playerRef && playerRef != null) {
      playerRef.currentTime(startTime + 1);
    }
  };

  const handleReview = async () => {
    setLoading(true);
    const res = await reviewApiCall();
    if (res) {
      toast.success(MESSAGE.SUCCESS.textVerified);
      setReviewStatus(true);
    }
    setLoading(false);
  };

  const reviewApiCall = async () => {
    let payload: PostReviewTextPayload = {
      productionResourceId: selectedVideoId,
      textResourceId: leftSubtitleData?._id,
      language: [defaultLang],
    };
    if (selectedVersion && selectedVersion.value !== "default") {
      payload.versionId = leftSubtitleData?.versions[selectedVersion.value]._id;
      const tempData: any = { ...leftSubtitleData };
      const selectedVersionValue = selectedVersion.value;
      tempData.versions[selectedVersionValue] = {
        ...tempData.versions[selectedVersionValue],
        isReviewed: true,
        reviewedBy: {
          firstName: currentUser?.firstName,
          lastName: currentUser?.lastName,
        },
      };
      setLeftSubtitleData(tempData);
      setReviewInfo({
        firstName: currentUser?.firstName,
        lastName: currentUser?.lastName,
      });
    } else {
      const tempData: any = { ...leftSubtitleData };
      tempData.isReviewed = true;
      tempData.reviewedBy = {
        firstName: currentUser?.firstName,
        lastName: currentUser?.lastName,
      };
      setLeftSubtitleData(tempData);
      setReviewInfo({
        firstName: currentUser?.firstName,
        lastName: currentUser?.lastName,
      });
    }
    setIsDataUpdate(true);
    try {
      let res = await postReviewText(payload);
      return res;
    } catch (err: any) {
      console.log(err);
      toast.error(err?.msg);
    }
  };

  // const handleSaveSummary = async () => {
  //   if (summaryText && summaryText.trim().length > 0) {
  //     await callSummeryUpdateApi();
  //   } else {
  //     toast.error("Empty text box: Please enter text before saving.");
  //   }
  // };

  // const callSummeryUpdateApi = async () => {
  //   try {
  //     setLoading(true);
  //     const payload: any = {
  //       summary: summaryText.trim(),
  //     };
  //     if (versionId && versionId !== "") {
  //       payload.versionId = versionId;
  //     } else {
  //       payload.id = leftSubtitleData?._id;
  //     }
  //     const res: any = await postUpdateSubTittleText(payload);
  //     toast.success(res.message);

  //     if (versionId && versionId !== "") {
  //       const tempData: any = { ...leftSubtitleData };
  //       const selectedVersionValue = selectedVersion.value;
  //       tempData.versions[selectedVersionValue] = {
  //         ...tempData.versions[selectedVersionValue],
  //         summary: summaryText,
  //       };
  //       setLeftSubtitleData(tempData);
  //     } else {
  //       const tempData: any = { ...leftSubtitleData };
  //       tempData.summary = summaryText;
  //       setLeftSubtitleData(tempData);
  //     }
  //     setLoading(false);
  //   } catch (err: any) {
  //     console.log(err);
  //     setLoading(false);
  //     toast.error(err?.msg || "something went wrong");
  //   }
  // };

  const toggleTooltip = () => {
    setTooltipOpen(!tooltipOpen);
  };
  const toggleTooltipOther = () => {
    setTooltipOpenForOther(!tooltipOpenForOther);
  };

  return (
    <div
      className={`subtitle-section ${
        activeItems.includes(TAB_TYPE.WAVE) ? "h-80" : ""
      }`}
      id="left-subtitle-section"
    >
      <div className="header">
        <div>
          <img src={SubtitleIcon} alt="Subtitle" />
        </div>
        <div
          className="close-btn"
          onClick={() => toggleActiveItem(TAB_TYPE.SUBTITLE)}
        >
          <img src={CloseIcon} alt="close" />
        </div>
      </div>
      <div className="section-divider">
        {dataStatus && !showManualTransSection ? (
          <div className="left-section">
            <div className="action" id="left-action">
              <div className="default-lang-outer">
                <div className="lang">
                  {defaultLang &&
                  selectedVideoInfo.defaultLanguage &&
                  selectedVideoInfo.defaultLanguage.length === 1 ? (
                    getFlagByLang(getLangName(defaultLang)) ? (
                      <>
                        <img
                          className="flag-icon cursor-pointer"
                          id="flag-lang-left"
                          src={`https://unpkg.com/language-icons/icons/${getFlagByLang(
                            getLangName(defaultLang)
                          )}.svg`}
                          alt="flag"
                        />
                        <Tooltip
                          placement="bottom"
                          isOpen={tooltipOpen}
                          autohide={true}
                          target={`flag-lang-left`}
                          toggle={toggleTooltip}
                        >
                          {getLangName(defaultLang)}
                        </Tooltip>
                      </>
                    ) : (
                      getLangName(defaultLang)
                    )
                  ) : selectedVideoInfo.defaultLanguage &&
                    selectedVideoInfo.defaultLanguage.length > 1 ? (
                    <>
                      <img
                        id="flag-lang-first"
                        className="flag-icon"
                        src={`https://unpkg.com/language-icons/icons/${selectedVideoInfo.defaultLanguage[0]}.svg`}
                        alt="flag"
                      />
                      <Tooltip
                        placement="bottom"
                        isOpen={tooltipOpen}
                        autohide={true}
                        target={`flag-lang-first`}
                        toggle={toggleTooltip}
                      >
                        {getLangName(selectedVideoInfo.defaultLanguage[0]) +
                          " ," +
                          getLangName(selectedVideoInfo.defaultLanguage[1])}
                      </Tooltip>{" "}
                      <img
                        id="flag-lang-first"
                        className="flag-icon"
                        src={`https://unpkg.com/language-icons/icons/${selectedVideoInfo.defaultLanguage[1]}.svg`}
                        alt="flag"
                      />
                      <>
                        <span id="flag-lang-other">
                          {selectedVideoInfo.defaultLanguage.length > 2 &&
                            ` (+${
                              selectedVideoInfo.defaultLanguage.length - 2
                            } more)`}
                        </span>
                        <Tooltip
                          placement="bottom"
                          isOpen={tooltipOpenForOther}
                          autohide={true}
                          target={`flag-lang-other`}
                          toggle={toggleTooltipOther}
                        >
                          {convertArrayToString(
                            selectedVideoInfo?.defaultLanguage
                          )}
                        </Tooltip>
                      </>
                    </>
                  ) : (
                    "Not Available"
                  )}
                </div>
                <div className="lang-add" onClick={toggleRightSection}>
                  <img src={AddIcon} alt="add" />
                </div>
              </div>
              <div className="action-copy-btn-outer">
                <div className="copy" title="copy" onClick={handleCopy}>
                  <img width={"15px"} src={CopyIcon} alt="copy" />
                </div>
                <div
                  className="download"
                  title="download"
                  onClick={handleDownloadClick}
                >
                  <img width={"15px"} src={DownloadIcon} alt="download" />
                </div>
              </div>
              {reviewStatus && (
                <>
                  <div>
                    <img
                      className="verify-icon"
                      src={VerifyIcon}
                      alt="verify"
                      id="verify"
                    />
                  </div>
                  <UncontrolledPopover
                    target="verify"
                    placement="top"
                    trigger="legacy"
                  >
                    <PopoverBody>
                      <div className="pop-over-section">
                        <div className="text-center">
                          Reviewed By :
                          {" " +
                            reviewInfo?.firstName +
                            " " +
                            reviewInfo?.lastName}
                        </div>
                      </div>
                    </PopoverBody>
                  </UncontrolledPopover>
                </>
              )}
              {leftSubtitleData &&
                leftSubtitleData.versions &&
                leftSubtitleData.versions.length > 0 && (
                  <div className="version-lang-outer">
                    <Select
                      className="version-list"
                      onChange={handleVersion}
                      value={selectedVersion}
                      options={versionData}
                      placeholder="Version"
                      isDisabled={isEdit ? true : false}
                    />
                    {!isEdit && isVersionView && (
                      <div
                        className="version-edit"
                        onClick={() => setIsEdit(true)}
                      >
                        <img src={EditIcon} alt="edit" />
                      </div>
                    )}
                    {isEdit && (
                      <div className="version-edit" onClick={handleSubmit}>
                        <img src={CheckCircleIcon} alt="submit" />
                      </div>
                    )}
                  </div>
                )}
              {leftSubtitleData &&
                leftSubtitleData?.versions &&
                leftSubtitleData?.versions.length === 0 &&
                !isEdit && (
                  <div
                    className="version-edit-single"
                    onClick={() => setIsEdit(true)}
                  >
                    <img src={EditIcon} alt="edit" />
                  </div>
                )}
              {leftSubtitleData &&
                leftSubtitleData?.versions &&
                leftSubtitleData?.versions.length === 0 &&
                isEdit && (
                  <div className="version-edit-single" onClick={handleSubmit}>
                    <img src={CheckCircleIcon} alt="submit" />
                  </div>
                )}
            </div>
            <div
              className={`subtitle-text-main-outer`}
              id="left-text-container"
            >
              {textResponse &&
                textResponse.length > 0 &&
                textResponse.map((_data: textType, index: any) => {
                  return (
                    <div className="subtitle-text-outer" key={index}>
                      <div className="heading">
                        <div className="time">
                          IN{" "}
                          {_data?.start && _data?.start > 0
                            ? secondsToMinutesAndHours(_data?.start)
                            : "00:00:00"}
                        </div>
                        {isEdit && (
                          <div
                            className="cursor-pointer"
                            onClick={() => handleDeleteSubtitleModal(_data)}
                          >
                            <img
                              className="delete-icon"
                              src={DeleteIcon}
                              alt="delete"
                            />
                          </div>
                        )}
                        <div className="time">
                          OUT{" "}
                          {_data?.end && _data?.end > 0
                            ? secondsToMinutesAndHours(_data?.end)
                            : "00:00:00"}
                        </div>
                      </div>
                      {!isEdit ? (
                        <div
                          className={`subtitle-text cursor-pointer ${
                            _data?.start <= videoPlayerTime &&
                            _data?.end >= videoPlayerTime
                              ? "active-text"
                              : ""
                          }`}
                          onClick={() => handleTextBoxClick(_data?.start)}
                        >
                          {_data?.text}
                        </div>
                      ) : (
                        <textarea
                          key={_data?.id}
                          className="text-area-subtitle"
                          defaultValue={_data?.text}
                          onChange={(e) =>
                            handleTextareaChange(index, e.target.value)
                          }
                        ></textarea>
                      )}
                    </div>
                  );
                })}
            </div>
            {/* {summaryBtnStatus && (
              <div className="summery-box">
                <div>
                  <label>Description</label>
                  <textarea
                    key={selectedVideoId}
                    className="text-area-subtitle"
                    value={summaryText}
                    rows={2}
                    onChange={(e) => setSummaryText(e.target.value)}
                  />
                  <div className="check-btn-outer">
                    <div className="check-btn" onClick={handleSaveSummary}>
                      Save
                    </div>
                  </div>
                </div>
              </div>
            )} */}

            <div className="subtitle-footer">
              {!reviewStatus && (
                <div className="check-btn" onClick={handleReview}>
                  <img src={RightIcon} alt="send" />
                  Review
                </div>
              )}
              {/* <div className="form-check form-switch form-switch-success form-switch-lg">
                <Input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  onChange={() => {
                    setSummaryBtnStatus(!summaryBtnStatus);
                  }}
                  checked={summaryBtnStatus}
                  id="flexSwitchCheckDefault"
                />
                <Label
                  className="form-check-label"
                  for="flexSwitchCheckDefault"
                >
                  Description
                </Label>
              </div> */}
            </div>
          </div>
        ) : (
          <>
            {!showManualTransSection && (
              <div className="manual-trans">
                <NotAvailable margin={false} msg={msg} />
                {showManualTransBtn && !showManualTransSection && (
                  <div
                    className="manual-trans-btn"
                    onClick={() => {
                      setShowManualTransSection(true);
                    }}
                  >
                    <div>
                      <img src={SubtitleIcon} alt="subtitle" />
                    </div>
                    <div className="manual-trans-btn-inner">
                      <div>Manual Transcribe</div>
                      <div className="info">Type your subtitle manually</div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </>
        )}
        {rightSection && <RightSection />}
        {showManualTransSection && (
          <ManualTransSection
            onClose={() => {
              setShowManualTransSection(false);
            }}
            onAddSubtitle={onAddSubtitle}
          />
        )}
        <DeleteModal
          show={showDeleteModal}
          onDeleteClick={handleDeleteSubtitle}
          onCloseClick={() => {
            setShowDeleteModal(!showDeleteModal);
          }}
          deleteType={true}
        />
      </div>
    </div>
  );
};

export default SubtitleSection;
