export const TabSectionType = [
  "Overview",
  "Booking",
  "Execution",
  "Acquisition",
  "Production",
  "Publishing",
  "Analytics",
  "Finances",
];

export const FileReviewStatus = {
  ToReview: 0,
  Reviewing: 1,
  Reviewed: 2,
  Rejected: 3,
};

export const RESOURCE_STATUS = {
  PENDING: 0,
  PROCESSING: 1,
  COMPLETED: 2,
  INIL_PROCESSING: "1",
};

export const REVIEWED_STATUS = {
  PENDING: "0",
  PROCESSING: "1",
  COMPLETED: "2",
};

export const QUEUE_TYPE = {
  STORY: 0,
  GLOBAL: 1,
};

export const SortData = [
  {
    value: "createdAt desc",
    label: "Latest",
    icon: "ri-sort-asc",
  },
  {
    value: "createdAt asc",
    label: "Older",
    icon: "ri-sort-desc",
  },
];

export const RESOURCE_QUEUE_TYPE = {
  MERGE: 1,
  COMPRESS: 2,
  AUDIO: 3,
  TEXT: 4,
  PROCESSING: "2",
};

export const VideoStatus = {
  Processing: "Processing",
};

export const UPLOAD_TAB_TYPE = {
  UPLOAD: 0,
  OPERATION: 1,
};

export const TAB_TYPE = {
  VIDEO: "VIDEO",
  FILE: "FILE",
  SEARCH: "SEARCH",
  SUBTITLE: "SUBTITLE",
  SHARE: "SHARE",
  WAVE: "WAVE",
  UPLOAD: "UPLOAD",
};

export const TAB_TYPE_DATA = [
  TAB_TYPE.VIDEO,
  TAB_TYPE.FILE,
  TAB_TYPE.SEARCH,
  TAB_TYPE.SUBTITLE,
  TAB_TYPE.SHARE,
  TAB_TYPE.WAVE,
  TAB_TYPE.UPLOAD,
];

export const VIDEO_STATUS = {
  UPLOADED: 0,
  PROCESSING: 1,
  COMPLETED: 2,
  NOT_PROCESSED: 3,
  NOT_TRANSCRIBED: 4,
  REPROCESS_VIDEO: 5,
  REPROCESS_VIDEO_COMPLETED: 6,
  REPROCESS_VIDEO_ERROR: 7,
  COMPRESS: 8,
  UPLOAD_FAILED: 9,
  TEMP_UPLOAD: 10,
};

export const VIDEO_STATUS_TYPE = {
  UPLOADED_SUCCESSFULLY: "Uploaded successfully",
  UPLOADING: "Uploading...",
  UPLOADING_IN_PROCESS: "Uploading in process...",
  COMPRESS_IN_PROCESS: "Compressing...",
  COMPLETED: "Completed",
};

export const VIDEO_RESPONSE_STATUS = {
  UPLOADING: 1,
  PROCESSING: 2,
  COMPRESSING: 3,
  COMPLETED: 4,
};

export const VIDEO_CARD_TYPE = {
  SHARE: "SHARE",
  INFO: "INFO",
};

export const allowedExtensions = ["mp4", "mov", "mxf", "mpeg", "m4v"];

export const LAYOUT_TYPES = {
  LAYOUT1: {
    value: [TAB_TYPE.FILE, TAB_TYPE.VIDEO],
    label: 1,
  },
  LAYOUT2: {
    value: [TAB_TYPE.FILE, TAB_TYPE.VIDEO, TAB_TYPE.WAVE],
    label: 2,
  },
  LAYOUT3: {
    value: [TAB_TYPE.UPLOAD, TAB_TYPE.FILE, TAB_TYPE.VIDEO, TAB_TYPE.WAVE],
    label: 3,
  },
  LAYOUT4: {
    value: [TAB_TYPE.SUBTITLE, TAB_TYPE.FILE, TAB_TYPE.VIDEO, TAB_TYPE.WAVE],
    label: 4,
  },
  LAYOUT5: {
    value: [TAB_TYPE.VIDEO, TAB_TYPE.SUBTITLE, TAB_TYPE.WAVE],
    label: 5,
  },
};

export const MAX_FILE_NAME_LENGTH = 100;
