import { getResources, postFetchTextDetails } from "../../ApiService/ApiCall";
import { VideoTextType } from "../interface/types";
import { getLangName } from "./helper";

export const updateVideoSelection = (
  videoId: string,
  transcriptionVideoList: any[],
  storeMethods: any
) => {
  const {
    setSelectedVideo,
    setDefaultLang,
    setLangList,
    setSelectedLang,
    setVideoLoad,
  } = storeMethods;

  setSelectedVideo(videoId);
  setVideoLoad(false);
  let videoList = transcriptionVideoList.filter(
    (_data) => _data._id === videoId
  );

  if (
    videoList &&
    videoList[0]?.languages &&
    videoList[0]?.languages.length >= 0
  ) {
    setDefaultLang(videoList[0]?.defaultLanguage["0"]);
    setLangList(videoList[0]?.languages);

    if (videoList[0]?.languages.length > 0) {
      setSelectedLang({
        value:
          videoList[0]?.languages[1]?.language[0] &&
          videoList[0]?.languages[1]?.language[0].toUpperCase(),
        label:
          videoList[0]?.languages[1]?.language &&
          getLangName(videoList[0]?.languages[1]?.language[0].toUpperCase()),
      });
    }
  } else {
    setLangList([]);
    setDefaultLang("");
    setSelectedLang({
      value: "",
      label: "",
    });
  }
  setVideoLoad(true);
};

export const getTextDetails = async (
  storeMethods: any,
  toast: any,
  videoId: string
) => {
  const {
    setLoading,
    setVideoLoad,
    setLeftSubtitleData,
    setRightSubtitleData,
    setSubtitleData,
  } = storeMethods;
  setLoading(true);
  setVideoLoad(false);
  let res: VideoTextType | any = await getTextDetailsApi(
    videoId,
    toast,
    storeMethods
  );

  if (res && res[0]) {
    setLeftSubtitleData(res[0]);
    setSubtitleData(res);
  }
  if (res && res[1]) {
    setRightSubtitleData(res[1]);
  } else {
    setRightSubtitleData("");
  }
  setVideoLoad(true);
  setLoading(false);
};

export const getTextDetailsApi = async (
  selectedVideoId: string,
  toast: any,
  storeMethods: any
) => {
  const { setLeftSubtitleData, setRightSubtitleData, setSubtitleData } =
    storeMethods;
  try {
    let res = await postFetchTextDetails({
      productionResourceId: selectedVideoId,
    });
    return res;
  } catch (err: any) {
    setSubtitleData("");
    setLeftSubtitleData("");
    setRightSubtitleData("");
    toast.error(err?.msg);
    console.log(err);
  }
};

const getTranscriptionList = async () => {
  try {
    let res: any = await getResources({
      skip: 0,
      limit: 10,
      sortBy: "createdAt desc",
    });
    return res;
  } catch (err: any) {
    console.log(err);
  }
};

export const getVideoListApi = async (
  storeMethods: any,
  toast: any,
  updateState: boolean
) => {
  const {
    setDefaultLang,
    setLangList,
    setSelectedLang,
    setSelectedVideo,
    setVideoLoad,
    setTranscriptionList,
    setLeftSubtitleData,
    setRightSubtitleData,
    setTotalCount,
    setPageCount
  } = storeMethods;
  let res = await getTranscriptionList();
  if (res && res?.result && res.result.length > 0) {
    setTranscriptionList(res?.result);
    setTotalCount(res?.total);
    setPageCount(Math.ceil(res?.total / 10))
    if (updateState) {
      setDefaultLang(res?.result[0]?.defaultLanguage[0]);
      getTextDetails(storeMethods, toast, res?.result[0]?._id);
      setSelectedVideo(res?.result[0]?._id);
    }
    if (res?.result[0]?.languages && res?.result[0].languages.length > 0 && updateState) {
      setLangList(res?.result[0]?.languages);
      setSelectedLang({
        value: res?.result[0]?.languages[1]?.language,
        label:
          res?.result[0]?.languages[1]?.language &&
          getLangName(res?.result[0]?.languages[1]?.language[0].toUpperCase()),
      });
    }
    if (res?.result[0]?.languages.length === 0 && res?.result[0]?.url && updateState) {
        setVideoLoad(true);
        setLeftSubtitleData("");
        setRightSubtitleData("");
    }
  } else if (res && res?.result.length === 0 && updateState) {
    setTranscriptionList([]);
    setSelectedVideo("");
    setLangList([]);
    setDefaultLang("");
    setSelectedLang({
      value: "",
      label: "",
    });
  } else {
    setTranscriptionList([]);
    setSelectedVideo("");
    setLangList([]);
    setSelectedLang({
      value: "",
      label: "",
    });
  }
};
