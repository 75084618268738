import ReactPaginate from 'react-paginate';
import { PaginationProps } from '../../../../Common/interface/types';
import './Pagination.scss'

const Pagination = (props: PaginationProps) => {
  const { list, limit, selectedPage, skipCount, totalCount, pageCount, handlePageChange } = props
  return (
    <div className='pagination'>
      <div className='showing'>
        Showing {list.length >= limit ? skipCount + limit : list.length} / {totalCount}
      </div>
      <ReactPaginate
        breakLabel="..."
        nextLabel=">"
        onPageChange={handlePageChange}
        pageRangeDisplayed={1}
        marginPagesDisplayed={1}
        pageCount={pageCount}
        previousLabel="< "
        forcePage={selectedPage}
        renderOnZeroPageCount={null}
        breakClassName={'page-item'}
        breakLinkClassName={'page-link'}
        containerClassName={'pagination'}
        pageClassName={'page-item'}
        pageLinkClassName={'page-link'}
        previousClassName={'page-item'}
        previousLinkClassName={'page-link'}
        nextClassName={'page-item'}
        nextLinkClassName={'page-link'}
        activeClassName={'active'}
      />
    </div>
  )
}

export default Pagination