import { API_URL } from "../Constants/Environment";

//UPLOAD
export const POST_VIDEO_FILE_UPLOAD = "/users/upload";
export const POST_VIDEO_FILE_UPLOAD_V1 = "/users/v1/upload";
export const POST_VIDEO_UPLOAD = `${API_URL}/users/upload`;
export const POST_VIDEO_UPLOAD_V1 = `${API_URL}/users/v1/upload`;
export const GET_RESOURCES = "/users/resources";
export const GET_LANG_LIST = "/language/filter-list";
export const POST_VIDEO_STATUS = "/users/setVideoStatus";
export const POST_FETCH_TEXT_DETAILS = "/users/get-subtitle";
export const GET_QUEUE_RESOURCES = "/users/getQueueResources";
export const GET_CURRENT_USER = "/users/getCurrentUser";
export const POST_CREATE_USER = "/users/create";
export const GET_USER_LISTING = "/users/listing";
export const UPDATE_USER = "/users/update";
export const DELETE_USER = "/users/archive";
export const POST_CREATE_UPLOAD_URL = "/users/create-upload-url";
export const POST_URL_LIST = "/users/url-listing";
export const POST_URL_EXPIRE = "/users/expire-url";
export const GET_USER_LIST_FOR_SHARE = "/users/share-resource/user-listing";
export const POST_SHARE_RESOURCE = "/users/share-resource";
export const POST_UPDATE_TEXT = "/users/text-update";
export const POST_DELETE_RESOURCE = "/users/delete-resource";
export const POST_DELETE_URL = "/users/delete-url";
export const POST_DELETE_SUBTITLE = "/users/delete-subtitle";
export const GET_VALIDATE_URL = "/users/validate-url";
export const POST_ADD_SUBTITLE = "/users/add-subtitle";
export const POST_DELETE_TEXT_RESOURCE = "/users/delete-text-resource";
export const POST_REPROCESS_VIDEO = "users/reprocess-video";
export const GET_VIDEO_DETAIL = "users/video/id/detail";
export const POST_COMPRESS_FILE = "/users/v1/compress-file";
export const POST_REVIEW_TEXT = "/users/review-text";
export const POST_REPROCESS_AUDIO = "/users/reprocess-audio";
export const POST_USER_DEFAULT_SETTING = "/users/upsert-user-default-setting";
export const GET_AUDIO_WAVE_FORM_DATA = "/users/resources/audio-wave-form";
export const POST_FILE_RENAME = "/users/file-rename";
export const GET_TIME_SPEND = "/users/resources/time-spend";
