import axios from "axios";
import { API_URL, COOKIES_KEY } from "../Constants/Environment";
// default
axios.defaults.baseURL = API_URL;
// content type
axios.defaults.headers.post["Content-Type"] = "application/json";

// content type
const token = localStorage.getItem(COOKIES_KEY);
if (token) {
  const { access_token } = JSON.parse(token)
  if (access_token) {
    axios.defaults.headers.common["Authorization"] = "Bearer " + access_token;
  }
}

// intercepting to capture errors
axios.interceptors.response.use(
  function (response) {
    return response.data ? response.data : response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    let message;
    switch (error?.response?.data?.statusCode) {
      case 500:
        message = "Internal Server Error";
        break;
      case 401:
        message = "Invalid credentials";
        break;
      case 404:
        message = "Sorry! the data you are looking for could not be found";
        break;
      case 400:
        message = error?.response?.data?.message;
        break;
      default:
        message = error;
    }
    return Promise.reject({ "message": message, "msg": error?.response?.data?.message, "statusCode": error?.response?.data?.statusCode });
  }
);
/**
 * Sets the default authorization
 * @param {*} token
 */
const setAuthorization = (token: string) => {
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
};

class APIClient {
  /**
   * Fetches data from given url
   */

  get = (url: any, params: any) => {
    return axios.get(url, params);
  };

  /**
   * post given data to url
   */
  create = (url: any, data: any) => {
    return axios.post(url, data);
  };
}
const getLogInUser = () => {
  const user = sessionStorage.getItem("authUser");
  if (!user) {
    return null;
  } else {
    return JSON.parse(user);
  }
};

export { APIClient, setAuthorization, getLogInUser };