import React, { useEffect, useState } from "react";
import "./VideoJsPlayer.css";
import videojs from "video.js";
import "videojs-sprite-thumbnails";
import "video.js/dist/video-js.css";
import { toast } from "react-hot-toast";
import useTranscriptionStore from "../../../../Store/TranscriptionStore/TranscriptionStore";
import useVideoRefStore from "../../../../Store/VideoStore/VideoStore";
import { addSubtitle } from "../../../../Common/helper/helper";

import BackIcon from "../../../../assets/images/New/back.svg";
import BackWardIcon from "../../../../assets/images/New/backward.svg";
import PlayIcon from "../../../../assets/images/New/Play.svg";
import PauseIcon from "../../../../assets/images/New/Pause.png";
import ForwardIcon from "../../../../assets/images/New/forward.svg";
import NextIcon from "../../../../assets/images/New/next.svg";
import MarkInIcon from "../../../../assets/images/New/MarkIn.svg";
import MarkOutIcon from "../../../../assets/images/New/MarkOut.svg";
import ReplayIcon from "../../../../assets/images/New/Replay.svg";
import TrimIcon from "../../../../assets/images/New/trim.svg";
import useTabStore from "../../../../Store/TabStore/TabStore";
import { TAB_TYPE, VIDEO_STATUS } from "../../../../Constants/Common/Data";
import {
  getTextDetails,
  updateVideoSelection,
} from "../../../../Common/helper/videohelper";
import SegmentModel from "../../WaveSection/SegmentModel";
import {
  postReprocessAudio,
  postUpdateUserDefaultSetting,
} from "../../../../ApiService/ApiCall";
import useUserStore from "../../../../Store/UserStore/UserStore";
import { MESSAGE } from "../../../../Constants/Messages";

const VideoPlayer = (props: any) => {
  const { hideButton } = props;
  const [videoTotalDuration, setVideoTotalDuration] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [segmentAddStatus, SetSegmentAddStatus] = useState(false);
  const [segmentData, setSegmentData] = useState<any[]>([]);
  const [segmentModalStatus, setSegmentModalStatus] = useState(false);
  const [showMarkBtn, setShowMarkBtn] = useState(false);

  const playerRef = React.useRef<any>(null);
  const videoRef: any = React.useRef(null);

  const {
    setVideoLoad,
    setVideoPlayerTime,
    leftSubtitleData,
    selectedVideoInfo,
    selectedVideoId,
    transcriptionVideoList,
    subtitleData,
    setLoading,
  } = useTranscriptionStore((state: any) => ({
    setVideoLoad: state.setVideoLoad,
    setVideoPlayerTime: state.setVideoPlayerTime,
    leftSubtitleData: state.leftSubtitleData,
    selectedVideoInfo: state.selectedVideoInfo,
    selectedVideoId: state.selectedVideoId,
    transcriptionVideoList: state.transcriptionVideoList,
    subtitleData: state.subtitleData,
    setLoading: state.setLoading,
  }));

  const { currentUser } = useUserStore((state: any) => ({
    currentUser: state.currentUser,
  }));

  const { setPlayerRef, waveSufferRef, waveSufferRegionRef } = useVideoRefStore(
    (state: any) => ({
      setPlayerRef: state.setPlayerRef,
      waveSufferRef: state.waveSufferRef,
      waveSufferRegionRef: state.waveSufferRegionRef,
    })
  );

  const { activeItems } = useTabStore((state: any) => ({
    activeItems: state.activeItems,
  }));

  useEffect(() => {
    if (currentUser && currentUser?.configSetting) {
      setShowMarkBtn(currentUser?.configSetting?.isMarkInAllow || false);
    } else {
      setShowMarkBtn(false);
    }
  }, [currentUser]);

  const videoJsOptions = {
    autoplay: true,
    controls: true,
    responsive: true,
    muted: true,
    fluid: true,
    breakpoints: true,
    playbackRates: [0.5, 1, 1.5, 2],
    controlBar: {
      skipButtons: false,
      nextButton: false,
      pictureInPictureToggle:false,
      volumePanel: {
        inline: false,
        volumeControl: {
          vertical: true,
        },
      },
    },
    sources: [
      {
        src: selectedVideoInfo?.url,
        type: "video/mp4",
      },
    ],
    textTracks: addSubtitle(subtitleData),
    thumbnailUrl: selectedVideoInfo?.frameThumbnail,
    aspectRatio: "16:9",
  };

  useEffect(() => {
    if (!selectedVideoInfo?.url && selectedVideoId) {
      setVideoLoad(false);
      toast(MESSAGE.WARNING.uploadOneVideo);
    }
  }, [selectedVideoInfo, setVideoLoad, selectedVideoId]);

  useEffect(() => {
    if (!playerRef.current) {
      const videoElement = document.createElement("video-js");
      if (videoElement && videoRef.current) {
        videoElement.classList.add("vjs-big-play-centered");
        videoRef.current.appendChild(videoElement);
      }

      const player: any = (playerRef.current = videojs(
        videoElement,
        videoJsOptions,
        () => {
          videojs.log("player is ready");

          videoJsOptions.textTracks.forEach((track: any) => {
            player.addRemoteTextTrack(track, true);
          });

          const textTrackSettings = player.textTrackSettings;
          const userSubtitleSetting =
            currentUser?.userDefaultSetting?.subtitleSetting;

          if (textTrackSettings && userSubtitleSetting) {
            textTrackSettings.setValues(userSubtitleSetting);
          }

          var doneButton = document.querySelector(".vjs-done-button");

          if (doneButton) {
            doneButton.addEventListener("click", handleDoneButtonClick);
          }

          function handleDoneButtonClick() {
            var currentValues = textTrackSettings.getValues();

            textTrackSettings.setValues(currentValues);
            handleTrackSetting(currentValues);
          }

          // Add thumbnail plugin
          player.spriteThumbnails({
            url: videoJsOptions?.thumbnailUrl,
            interval: 1,
            width: 160,
            height: 90,
          });

          player.on("loadedmetadata", () => {
            const duration = player.duration();
            setVideoTotalDuration(duration);
          });

          player.on(["pause", "ended"], () => {
            setIsPlaying(true);
          });

          player.on(["play"], () => {
            setIsPlaying(false);
          });
          handlePlayerReady(player);
        }
      ));
      setPlayerRef(player);
    } else {
      const player = playerRef.current;

      player.autoplay(videoJsOptions.autoplay);
      setIsPlaying(videoJsOptions.autoplay);
      player.src(videoJsOptions.sources);
    }

    // setWaveSufferRef(waveSurferRef)

    return () => {
      if (playerRef.current && !playerRef.current.isDisposed()) {
        playerRef.current.dispose();
        playerRef.current = null;
        setPlayerRef(null);
      }

      // if (waveSurferRef.current) {
      //   // waveSurferRef.current.dispose();
      //   waveSurferRef.current = null;
      //   setWaveSufferRef(null)
      // }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [playerRef, videoRef, waveSurferRef, leftSubtitleData]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playerRef, videoRef, leftSubtitleData, subtitleData]);

  const handleNextBtn = () => {
    const videoIndex = transcriptionVideoList.findIndex(
      (data: any) => data?._id === selectedVideoId
    );
    if (
      transcriptionVideoList.length > 0 &&
      transcriptionVideoList.length > videoIndex + 1
    ) {
      handleVideo(transcriptionVideoList[videoIndex + 1]?._id);
    }
  };

  const handlePrevBtn = () => {
    const videoIndex = transcriptionVideoList.findIndex(
      (data: any) => data?._id === selectedVideoId
    );
    if (transcriptionVideoList.length > 0 && videoIndex > 0) {
      handleVideo(transcriptionVideoList[videoIndex - 1]?._id);
    }
  };

  const handleVideo = async (videoId: string) => {
    const storeMethods = useTranscriptionStore.getState();
    updateVideoSelection(videoId, transcriptionVideoList, storeMethods);
    if (!activeItems.includes(TAB_TYPE.FILE)) {
      if (selectedVideoInfo && selectedVideoInfo?.defaultLanguage) {
        await getTextDetails(storeMethods, toast, videoId);
      }
    }
  };

  const handlePlayPauseClick = () => {
    if (playerRef.current) {
      if (!isPlaying) {
        playerRef.current.pause();
      } else {
        playerRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const handleSeekForward = () => {
    seekVideo(10);
  };

  const handleSeekBackward = () => {
    seekVideo(-10);
  };

  const seekVideo = (seconds: number) => {
    if (playerRef.current) {
      const currentTime = playerRef.current.currentTime();
      const newTime = currentTime + seconds;

      // Ensure the new time is within the valid range (0 to videoTotalDuration)
      const clampedTime = Math.max(0, Math.min(newTime, videoTotalDuration));

      playerRef.current.currentTime(clampedTime);
    }
  };

  const handlePlayerReady = (player: any) => {
    playerRef.current = player;

    player.on("timeupdate", () => {
      const time = player.currentTime();
      setVideoPlayerTime(time);
    });
  };

  const handleReplay = () => {
    playerRef.current.currentTime(0);
  };

  const addSegment = () => {
    if (activeItems.includes(TAB_TYPE.WAVE)) {
      if (waveSufferRef && !segmentAddStatus) {
        const time = playerRef.current.currentTime();
        const temp = { start: time, end: time };
        setSegmentData([...segmentData, temp]);
        SetSegmentAddStatus(true);
      }
    }
  };

  const endSegment = () => {
    if (activeItems.includes(TAB_TYPE.WAVE)) {
      if (waveSufferRef) {
        const newEndTime = playerRef.current.currentTime();
        if (segmentData && segmentData.length > 0) {
          const updatedArray = [...segmentData];
          const lastObj = updatedArray[updatedArray.length - 1];
          lastObj.end = newEndTime;

          setSegmentData(updatedArray);
          addRegionOnWaveForm();
        }
        SetSegmentAddStatus(false);
      }
    }
  };

  const addRegionOnWaveForm = () => {
    if (segmentData && segmentData.length > 0) {
      segmentData.forEach((subtitle: any, index: number) => {
        const start = subtitle.start;
        const end = subtitle.end;
        const id = `custom index-${index}`;
        let content = "";

        waveSufferRegionRef.addRegion({
          id,
          start,
          end,
          content,
          drag: false,
          // color: "#99CCFF",
          border: "1px solid red",
          attributes: {
            class: `wavesurfer-region important region11-${index}`,
            'div[part="region-content12"]': `region-content11-${index}`,
          },
          // style: {
          //   textAlign: "center !important",
          //   marginTop: "50px !important",
          // },
        });
      });
    }
  };

  const showSegment = () => {
    let tempRegion = waveSufferRegionRef.getRegions();
    let region = tempRegion.slice(tempRegion.length - segmentData.length);
    const temp: any = [];
    region.forEach((_data: any) => {
      temp.push({
        start: _data?.start,
        end: _data?.end,
        content: _data?.content,
      });
    });
    setSegmentData(temp);
    setSegmentModalStatus(true);
  };

  const handleSegmentApi = async (data: []) => {
    const storeMethods = useTranscriptionStore.getState();
    setLoading(true);
    const result: any = await callSegmentApi(data);
    if (result.status) {
      toast.success(result.msg);
      await getTextDetails(storeMethods, toast, selectedVideoId);
    }
    setLoading(false);
  };

  const callSegmentApi = async (data: []) => {
    try {
      const res = await postReprocessAudio({
        productionResourceId: selectedVideoId,
        data: data,
      });
      return res;
    } catch (error) {
      console.log(error);
    }
  };

  const handleTrackSetting = async (currentSetting: any) => {
    if (currentUser && currentUser?.configSetting?.isSubtitleChangeAllow) {
      setLoading(true);
      await callUpdateTabApi(currentSetting);
      setLoading(false);
    }
  };

  const callUpdateTabApi = async (currentSetting: any) => {
    try {
      await postUpdateUserDefaultSetting({
        subtitleSetting: currentSetting,
      });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="video-player-outer" key={selectedVideoId}>
      {/* <div className='title-outer'>
        <div className='video-title'>{shortName(selectedInfo?.title, 40)}</div>
      </div> */}
      <div className="player-outer">
        <div data-vjs-player>
          <div ref={videoRef} />
        </div>
      </div>
      {/* <div className='custom-progress-bar' onClick={handleProgressBarClick}>
        <Progress
          className='progress-bar-inner'
          style={{ width: `${(videoTime / videoTotalDuration) * 100}%` }}
        />
        <div className='player-time-info'>
          <div>{secondsToMinutesAndHours(videoTime)}</div>
          <div>{secondsToMinutesAndHours(videoTotalDuration)}</div>
        </div>
      </div> */}
      <div className={`video-action ${activeItems.length > 0 ? "small" : ""}`}>
        <div className="btn" onClick={handleReplay} title="Replay">
          <img src={ReplayIcon} alt="replay" />
        </div>
        {showMarkBtn && activeItems.includes(TAB_TYPE.WAVE) && (
          <div
            className={`${
              activeItems.includes(TAB_TYPE.WAVE) &&
              selectedVideoInfo?.status === VIDEO_STATUS.COMPLETED &&
              !segmentAddStatus
                ? "btn"
                : "btn disable"
            }`}
            title="Mark In"
            onClick={addSegment}
          >
            <img src={MarkInIcon} alt="markIn" />
          </div>
        )}
        <div className="btn" onClick={handlePrevBtn}>
          <img src={BackIcon} alt="back" />
        </div>
        {!hideButton && !activeItems.includes(TAB_TYPE.WAVE) && (
          <div className="btn" onClick={handleSeekBackward}>
            <img src={BackWardIcon} alt="backward" />
          </div>
        )}
        <div className="btn play" onClick={handlePlayPauseClick}>
          {isPlaying ? (
            <img src={PlayIcon} alt="play" />
          ) : (
            <img src={PauseIcon} alt="pause" />
          )}
        </div>
        {!hideButton && !activeItems.includes(TAB_TYPE.WAVE) && (
          <div className="btn" onClick={handleSeekForward}>
            <img src={ForwardIcon} alt="forward" />
          </div>
        )}
        <div className="btn" onClick={handleNextBtn}>
          <img src={NextIcon} alt="next" />
        </div>
        {showMarkBtn && activeItems.includes(TAB_TYPE.WAVE) && (
          <div
            className={`${
              activeItems.includes(TAB_TYPE.WAVE) &&
              selectedVideoInfo?.status === VIDEO_STATUS.COMPLETED &&
              segmentAddStatus
                ? "btn"
                : "btn disable"
            }`}
            onClick={endSegment}
            title="Mark Out"
          >
            <img src={MarkOutIcon} alt="markOut" />
          </div>
        )}
        {showMarkBtn && activeItems.includes(TAB_TYPE.WAVE) && (
          <div
            className={`${
              activeItems.includes(TAB_TYPE.WAVE) &&
              segmentData &&
              segmentData.length > 0
                ? "btn"
                : "btn disable"
            }`}
            onClick={showSegment}
            title="Show"
          >
            <img src={TrimIcon} alt="Segment" />
          </div>
        )}
      </div>
      <SegmentModel
        segmentData={segmentData}
        onSubmit={handleSegmentApi}
        show={segmentModalStatus}
        onCloseClick={() => setSegmentModalStatus(false)}
      />
      {/* <div className='wave-form' ref={waveSurferRef} /> */}
    </div>
  );
};

export default VideoPlayer;
