import { useEffect, useState } from "react";
import "./UploadFile.css";
import { FilePond, registerPlugin } from "react-filepond";
import { useLocation } from "react-router-dom";
import "filepond/dist/filepond.min.css";
import useTranscriptionStore from "../../Store/TranscriptionStore/TranscriptionStore";
import {
  POST_VIDEO_UPLOAD,
  GET_VALIDATE_URL,
  POST_VIDEO_UPLOAD_V1,
} from "../../ApiService/UrlHelper";
import { toast } from "react-hot-toast";
import axios from "axios";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import {
  checkFileNameLength,
  getExtension,
  isAllowedExtension,
} from "../../Common/helper/helper";
import { postCompressFile } from "../../ApiService/ApiCall";
import { MESSAGE } from "../../Constants/Messages";

registerPlugin(FilePondPluginFileValidateType);

const VideoFileUploader = () => {
  const [files, setFiles] = useState([]);
  const [isShowError, setIsShowError] = useState(false);
  const [isCompressed, setIsCompressed] = useState(false);
  const [serverUrl, setServerUrl] = useState(POST_VIDEO_UPLOAD);
  let UPLOAD_URL = POST_VIDEO_UPLOAD;

  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const { setListUpdateStatus } = useTranscriptionStore((state: any) => ({
    setListUpdateStatus: state.setListUpdateStatus,
  }));

  const handleUpdate = (fileItems: any) => {
    setFiles(fileItems);
  };

  const handleFileUploadSuccess = async (file: any, serverResponse: any) => {
    const response: any = JSON.parse(serverResponse);
    try {
      setTimeout(() => {
        setFiles([]);
        setListUpdateStatus(true);
      }, 2000);
      if (
        !localStorage.getItem("API_VERSION") &&
        localStorage.getItem("API_VERSION") === "true" &&
        response &&
        response?.rawResourceId
      ) {
        postCompressFileApi(response?.rawResourceId);
      }
      toast.success(`Video (${file?.file?.name})  Uploaded`);
    } catch (error) {
      toast.error(MESSAGE.ERROR.uploadFail);
    }
  };

  const postCompressFileApi = async (rawResourceId: string) => {
    try {
      await postCompressFile({
        rawResourceId: rawResourceId,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleAdd = async (file: any) => {
    await validateUrl();
    if (isAllowedExtension(file?.file?.name)) {
      if (checkFileNameLength(file?.file?.name)) {
        if (getExtension(file?.file?.name) === "mp4") {
          setIsCompressed(true);
        } else {
          setIsCompressed(false);
        }
      } else {
        setTimeout(() => {
          setFiles([]);
        }, 1000);
        file?.abortProcessing();
        toast.error(MESSAGE.ERROR.fileRenameError);
      }
    } else {
      setTimeout(() => {
        setFiles([]);
      }, 1000);
      file?.abortProcessing();
      toast.error(MESSAGE.ERROR.fileFormatError);
    }
  };

  useEffect(() => {
    if (
      localStorage.getItem("API_VERSION") &&
      localStorage.getItem("API_VERSION") === "true"
    ) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      UPLOAD_URL = POST_VIDEO_UPLOAD_V1;
    } else {
      localStorage.setItem("API_VERSION", "false");
    }
    setServerUrl(UPLOAD_URL + `?isCompressed=${isCompressed}`);
  }, [isCompressed]);

  const validateUrl = async () => {
    try {
      const accessToken = params.get("access_token");
      await axios.get(GET_VALIDATE_URL, {
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      });
      setIsShowError(false);
    } catch (err: any) {
      console.log("err", err);
      if (err?.statusCode === 401) {
        setIsShowError(true);
      }
    }
  };

  useEffect(() => {
    setServerUrl(POST_VIDEO_UPLOAD + `?isCompressed=${isCompressed}`);
  }, [isCompressed]);

  useEffect(() => {
    validateUrl()
      .then(() => {})
      .catch((error) => {
        console.error("Error during validation:", error);
        //setIsShowError(true);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleError = (error: any) => {
    if (error?.code === 401) {
      // toast.error("Shared link expired");
      setIsShowError(true);
    } else if (error?.code === 500) {
      toast.error(MESSAGE.ERROR.fileFormatError);
    } else {
      toast.error(MESSAGE.ERROR.uploadFail);
    }
    setTimeout(() => {
      setFiles([]);
    }, 2000);
  };

  return (
    <>
      <div className="video-container">
        <div className="video-uploader-outer">
          {isShowError === true ? (
            <div className="error-container">Link Expired</div>
          ) : (
            <FilePond
              files={files}
              onaddfile={(err, file) => handleAdd(file)}
              onupdatefiles={handleUpdate}
              allowMultiple={false}
              allowFileTypeValidation={false}
              maxFiles={1}
              className="file-pond"
              server={{
                url: serverUrl,
                headers: {
                  Authorization: "Bearer " + params.get("access_token"),
                },
              }}
              onerror={(error) => handleError(error)}
              onprocessfile={(error, file) => {
                if (!error) {
                  handleFileUploadSuccess(file, file.serverId);
                }
              }}
              acceptedFileTypes={[
                "video/mp4",
                "video/quicktime",
                "video/mov",
                "video/mpeg",
                "application/mxf",
              ]}
              name="files"
              labelIdle=' <span class="filepond--label-action"><i class="ri-upload-2-line"></i></span> Upload File'
              labelFileTypeNotAllowed="File of invalid type"
              fileValidateTypeLabelExpectedTypes=""
            />
          )}
        </div>
      </div>
    </>
  );
};

export default VideoFileUploader;
