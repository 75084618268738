import "./DefaultSection.scss";
import Arrow from "../../../assets/images/New/arrow.svg";
import File from "../../../assets/images/New/file.svg";
import Panel from "../../../assets/images/New/panel.svg";
// import Search from '../../../assets/images/New/search.svg'
// import Share from '../../../assets/images/New/share.svg'
import Text from "../../../assets/images/New/text.svg";
import Video from "../../../assets/images/New/video.svg";
import useTabStore from "../../../Store/TabStore/TabStore";
import { TAB_TYPE } from "../../../Constants/Common/Data";
import useUserStore from "../../../Store/UserStore/UserStore";
import { useEffect, useState } from "react";

const DefaultSection = () => {
  const { toggleActiveItem, activeItems } = useTabStore((state: any) => ({
    toggleActiveItem: state.toggleActiveItem,
    activeItems: state.activeItems,
  }));

  const { currentUser } = useUserStore((state: any) => ({
    currentUser: state.currentUser,
  }));

  const [isWaveFormShow, setIsWaveFormShow] = useState(false);

  const handleWaveActive = () => {
    toggleActiveItem(TAB_TYPE.WAVE);
    if (!activeItems.includes(TAB_TYPE.VIDEO)) {
      toggleActiveItem(TAB_TYPE.VIDEO);
    }
  };

  useEffect(() => {
    if (currentUser && currentUser?.configSetting) {
      setIsWaveFormShow(currentUser?.configSetting?.isWaveformAllow || false);
    } else {
      setIsWaveFormShow(false);
    }
  }, [currentUser]);

  return (
    <div className="default-section">
      <div className="title">Open a pane to get started.</div>
      <div className="action">
        <div
          className={`custom-bottom arrow`}
          onClick={() => toggleActiveItem(TAB_TYPE.UPLOAD)}
        >
          <img alt="arrow" src={Arrow} />
        </div>
        <div
          className={`custom-bottom file-tab `}
          onClick={() => toggleActiveItem(TAB_TYPE.FILE)}
        >
          <img alt="file" src={File} />
        </div>
        {/* <div className={`custom-bottom search`}
          onClick={() => toggleActiveItem(TAB_TYPE.SEARCH)}
        >
          <img alt="search" src={Search} />
        </div> */}
        <div
          className={`custom-bottom text`}
          onClick={() => toggleActiveItem(TAB_TYPE.SUBTITLE)}
        >
          <img alt="text" src={Text} />
        </div>
        <div
          className={`custom-bottom video `}
          onClick={() => toggleActiveItem(TAB_TYPE.VIDEO)}
        >
          <img alt="video" src={Video} />
        </div>
        {/* <div className={`custom-bottom share `}
          onClick={() => toggleActiveItem(TAB_TYPE.SHARE)}
        >
          <img alt="share" src={Share} />
        </div> */}
        {isWaveFormShow && (
          <div className={`custom-bottom panel`} onClick={handleWaveActive}>
            <img alt="panel" src={Panel} />
          </div>
        )}
      </div>
    </div>
  );
};

export default DefaultSection;
