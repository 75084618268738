import "./Header.scss";
import Arrow from "../../../assets/images/New/arrow.svg";
import File from "../../../assets/images/New/file.svg";
import Panel from "../../../assets/images/New/panel.svg";
// import Search from '../../../assets/images/New/search.svg'
// import Share from '../../../assets/images/New/share.svg'
import Text from "../../../assets/images/New/text.svg";
import Video from "../../../assets/images/New/video.svg";
import useTabStore from "../../../Store/TabStore/TabStore";
import { TAB_TYPE } from "../../../Constants/Common/Data";
import { useEffect, useState } from "react";
import Upload from "./Upload/Upload";
import useUserStore from "../../../Store/UserStore/UserStore";
import useTranscriptionStore from "../../../Store/TranscriptionStore/TranscriptionStore";
import { toast } from "react-hot-toast";
import { MESSAGE } from "../../../Constants/Messages";

const NewHeader = () => {
  const { activeItems, toggleActiveItem, setWindowSmall } = useTabStore(
    (state: any) => ({
      activeItems: state.activeItems,
      toggleActiveItem: state.toggleActiveItem,
      setWindowSmall: state.setWindowSmall,
    })
  );

  const { currentUser } = useUserStore((state: any) => ({
    currentUser: state.currentUser,
  }));

  const { transcriptionVideoList } = useTranscriptionStore();

  const isActive = (item: string) => activeItems.includes(item);

  const [isWaveFormShow, setIsWaveFormShow] = useState(false);

  useEffect(() => {
    if (currentUser && currentUser?.configSetting) {
      setIsWaveFormShow(currentUser?.configSetting?.isWaveformAllow || false);
    } else {
      setIsWaveFormShow(false);
    }
  }, [currentUser]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 765) {
        // console.log('Screen size is less than 765 pixels.');
        setWindowSmall(true);
      } else {
        setWindowSmall(false);
      }
    };
    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleWaveActive = () => {
    if (
      !activeItems.includes(TAB_TYPE.VIDEO) &&
      !activeItems.includes(TAB_TYPE.WAVE)
    ) {
      toggleActiveItem(TAB_TYPE.VIDEO);
      toggleActiveItem(TAB_TYPE.WAVE);
    } else {
      toggleActiveItem(TAB_TYPE.WAVE);
    }
  };

  const handleVideoActive = () => {
    if (transcriptionVideoList && transcriptionVideoList.length > 0) {
      toggleActiveItem(TAB_TYPE.VIDEO);
    } else {
      toast(MESSAGE.WARNING.uploadVideo);
    }
  };

  const drag = (ev: any, tabType: string) => {
    ev.dataTransfer.setData("text", tabType);
  };

  return (
    <div className="header-section">
      <div className="icon-outer">
        <div className="title">VideoApp</div>
        <div
          className={`custom-border-bottom arrow  ${
            isActive(TAB_TYPE.UPLOAD) ? "active" : ""
          }`}
          onClick={() => toggleActiveItem(TAB_TYPE.UPLOAD)}
          id={TAB_TYPE.UPLOAD}
          draggable="true"
          onDragStart={(e) => drag(e, TAB_TYPE.UPLOAD)}
        >
          <img
            onDragStart={(e) => drag(e, TAB_TYPE.UPLOAD)}
            alt="arrow"
            src={Arrow}
          />
        </div>
        <div
          className={`custom-border-bottom file-tab ${
            isActive(TAB_TYPE.FILE) ? "active" : ""
          }`}
          onClick={() => toggleActiveItem(TAB_TYPE.FILE)}
          id={TAB_TYPE.FILE}
          draggable="true"
          onDragStart={(e) => drag(e, TAB_TYPE.FILE)}
        >
          <img
            alt="file"
            onDragStart={(e) => drag(e, TAB_TYPE.FILE)}
            src={File}
          />
        </div>
        {/* <div className={`custom-border-bottom search ${isActive(TAB_TYPE.SEARCH) ? 'active' : ''}`}
          onClick={() => toggleActiveItem(TAB_TYPE.SEARCH)} draggable='true' onDragStart={(e) => drag(e)}
        >
          <img id={TAB_TYPE.SEARCH} alt="search" src={Search} />
        </div> */}
        <div
          className={`custom-border-bottom text ${
            isActive(TAB_TYPE.SUBTITLE) ? "active" : ""
          }`}
          onClick={() => toggleActiveItem(TAB_TYPE.SUBTITLE)}
          draggable="true"
          id={TAB_TYPE.SUBTITLE}
          onDragStart={(e) => drag(e, TAB_TYPE.SUBTITLE)}
        >
          <img
            alt="text"
            onDragStart={(e) => drag(e, TAB_TYPE.SUBTITLE)}
            src={Text}
          />
        </div>
        <div
          className={`custom-border-bottom video ${
            isActive(TAB_TYPE.VIDEO) ? "active" : ""
          }`}
          onClick={() => handleVideoActive()}
          draggable="true"
          id={TAB_TYPE.VIDEO}
          onDragStart={(e) => drag(e, TAB_TYPE.VIDEO)}
        >
          <img
            alt="video"
            onDragStart={(e) => drag(e, TAB_TYPE.VIDEO)}
            src={Video}
          />
        </div>
        {/* <div className={`custom-border-bottom share ${isActive(TAB_TYPE.SHARE) ? 'active' : ''}`}
          onClick={() => toggleActiveItem(TAB_TYPE.SHARE)} draggable='true' onDragStart={(e) => drag(e)}
        >
          <img id={TAB_TYPE.SHARE} alt="share" src={Share} />
        </div> */}
        {isWaveFormShow && (
          <div
            className={`custom-border-bottom panel ${
              isActive(TAB_TYPE.WAVE) ? "active" : ""
            }`}
            onClick={handleWaveActive}
            draggable="true"
            onDragStart={(e) => drag(e, TAB_TYPE.WAVE)}
          >
            <img
              id={TAB_TYPE.WAVE}
              onDragStart={(e) => drag(e, TAB_TYPE.WAVE)}
              alt="panel"
              src={Panel}
            />
          </div>
        )}
        <Upload />
        {/* <Downlaod /> */}
      </div>
    </div>
  );
};

export default NewHeader;
