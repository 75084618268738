import { useEffect, useMemo, useState } from "react";
import { getUrlList, postUrlExpire } from "../ApiService/ApiCall";
import { toast } from "react-hot-toast";
import { urlType } from "../Common/interface/types";
import dayjs from "dayjs";
import {
  Card,
  CardBody,
  Col,
  Container,
  Modal,
  ModalBody,
  Row,
} from "reactstrap";
import SpinnerLoader from "../Components/Common/Spinner/Spinner";
import BreadCrumb from "../Components/Common/BreadCrumb";
import TableContainerComp from "../Components/Common/TableContainer";
import { shortName } from "../Common/helper/helper";
import { MESSAGE } from "../Constants/Messages";

const UrlListing = () => {
  const [expireModalStatus, setExpireModalStatus] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<string>("");
  const [urlList, setUrlList] = useState<urlType[]>([]);
  const [skipLimit, setSkipLimit] = useState(0);
  const [pageSize] = useState(10);
  const [pageIndex, setPageIndex] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [totalCountOfPage, setTotalCountOfPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [sortOrder, setSortOrder] = useState({
    column: "createdAt",
    order: "desc",
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        await getUrlListApi(pageSize);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageIndex, pageSize, sortOrder]);

  //To get url list
  const getUrlListApi = async (pageSize: number) => {
    try {
      setIsLoading(true);
      const skip = pageIndex * pageSize;
      const limit = pageSize;
      let res: any = await getUrlList({
        skip: skip,
        limit: limit,
        sortBy: `${sortOrder.column} ${sortOrder.order}`,
      });

      const urlTotalCount = res.totalCount || 0;
      const calculatedTotalPages = Math.ceil(urlTotalCount / pageSize);

      setUrlList(res?.result || []);
      setSkipLimit(skip);
      setTotalCountOfPage(urlTotalCount);
      setTotalPages(calculatedTotalPages);
      setIsLoading(false);
      return res?.result || [];
    } catch (err: any) {
      toast.error(err);
      console.log(err);
    }
  };

  const handleNextPage = () => {
    setPageIndex((prevIndex) => {
      const newIndex = Math.min(prevIndex + 1, totalPages - 1);
      return newIndex;
    });
  };

  const handlePreviousPage = () => {
    setPageIndex((prevIndex) => {
      const newIndex = Math.max(prevIndex - 1, 0);
      return newIndex;
    });
  };

  const handlePageChange = (page: number) => {
    setPageIndex(page);
  };

  const modalClose = () => {
    setExpireModalStatus(!expireModalStatus);
  };

  const handleExpireUrl = async () => {
    try {
      await postUrlExpire({ id: selectedId });
      await getUrlListApi(pageSize);
      toast.success(MESSAGE.SUCCESS.linkDeactivated);
      setExpireModalStatus(false);
    } catch (err: any) {
      toast.error(err?.msg || "Something went wrong");
      setExpireModalStatus(false);
      console.error(err);
    }
  };

  const handleCopy = (url: string) => {
    navigator.clipboard.writeText(url);
    toast.success(MESSAGE.SUCCESS.textCopied);
  };

  const checkExpire = (date: string) => {
    const currentTime = dayjs();

    const inputTime = dayjs(date);

    return inputTime.isAfter(currentTime);
  };

  const onClickExpire = async (id: string) => {
    setSelectedId(id);
    setExpireModalStatus(true);
  };
  const formatTimeAgo = (createdAt: string) => {
    const now = dayjs();
    const createdTime = dayjs(createdAt);
    const diffInMinutes = now.diff(createdTime, "minutes");

    if (diffInMinutes < 60) {
      return `${diffInMinutes} minutes ago`;
    } else if (diffInMinutes < 24 * 60) {
      const diffInHours = Math.floor(diffInMinutes / 60);
      return `${diffInHours} hours ago`;
    } else if (diffInMinutes < 10 * 24 * 60) {
      const diffInDays = Math.floor(diffInMinutes / (24 * 60));
      return `${diffInDays} days ago`;
    } else {
      return dayjs(createdAt).format('DD-MM-YYYY hh:mm:ss A');
    }
  };

  const handleSortToggle = (columnName: string) => {
    setSortOrder(prevSortOrder => ({
      column: columnName,
      order: prevSortOrder.order === "asc" ? "desc" : "asc",
    }));
  };

  // Column
  const columns = useMemo(
    () => [
      {
        Header: "Sr No.",
        accessor: (row: any, index: any) => {
          return skipLimit + 1 + index;
        },
        filterable: false,
      },
      {
        Header: "Url",
        accessor: "url",
        filterable: false,
        disableSortBy: true,
        Cell: (urlList: any) => (
          <>
            {urlList.row.original.url && (
              <div>
                {shortName(urlList.row.original.url, 45)}
                <span
                  className="cursor-pointer"
                  onClick={() => handleCopy(urlList.row.original.url)}
                >
                  <i className="ri-file-copy-line px-3"></i>
                </span>
              </div>
            )}
          </>
        ),
      },

      {
        Header: "Expire",
        accessor: "expireIn",
        filterable: false,
        Cell: (urlList: any) => {
          if (
            !urlList.row.original.isExpire &&
            checkExpire(urlList.row.original.expireIn)
          ) {
            return (
              <div>
                {dayjs(urlList.row.original.expireIn).format(
                  "DD-MM-YYYY hh:mm:ss A"
                )}
              </div>
            );
          } else {
            return (
              <div className="badge text-uppercase bg-danger-subtle text-danger">
                Expired
              </div>
            );
          }
        },
      },
      {
        Header: (
          <div className="align-items-center" title="Sort">
            <span>Created Date</span>
            <i
              className={` ${sortOrder.order === "asc" ? "ri-arrow-up-line" : "ri-arrow-down-line"
                } cursor-pointer mx-1`}
              onClick={() => handleSortToggle("createdAt")}
            ></i>
          </div>
        ),
        accessor: "createdAt",
        filterable: false,
        disableSortBy: true,
        Cell: (urlList: any) => (
          <>
            {urlList.row.original.createdAt && (
              <div>{formatTimeAgo(urlList.row.original.createdAt)}</div>
            )}
          </>
        ),
      },
      {
        Header: "Action",
        Cell: (cellProps: any) => (
          <>
            {!cellProps.row.original.isExpire &&
              checkExpire(cellProps.row.original.expireIn) && (
                <>
                  <button
                    className="btn btn-danger"

                    onClick={() => {
                      onClickExpire(cellProps.row.original?._id);
                    }}
                  >
                    Expire Now
                  </button>
                </>
              )}
          </>
        ),
      },
    ],
    [skipLimit, sortOrder]
  );

  return (
    <>
      {isLoading ? <SpinnerLoader /> : ""}
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Url" pageTitle={""} />
          <Row>
            <Col lg={12}>
              <Card id="leadsList">
                <CardBody className="pt-3">
                  <div>
                    {urlList && urlList.length ? (
                      <TableContainerComp
                        columns={columns}
                        data={urlList || []}
                        customPageSize={10}
                        className="custom-header-css"
                        divClass="table-responsive table-card"
                        tableClass="align-middle"
                        theadClass="table-light"
                        totalCount={totalCountOfPage}
                        handleNextPage={handleNextPage}
                        handlePreviousPage={handlePreviousPage}
                        handlePageChange={handlePageChange}
                        pageIndex={pageIndex}
                      />
                    ) : (
                      <span>No Url Found</span>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <Modal
          fade={true}
          isOpen={expireModalStatus}
          toggle={modalClose}
          centered={true}
        >
          <ModalBody className="py-3 px-5">
            <div className="mt-2 text-center">
              <i className="ri-time-fill display-5 text-danger"></i>
              <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                <h4>Are you sure ?</h4>
                <p className="text-muted mx-4 mb-0">
                  Are you sure you want to Expired this url ?
                </p>
              </div>
            </div>
            <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
              <button
                type="button"
                className="btn w-sm btn-light"
                data-bs-dismiss="modal"
                onClick={modalClose}
              >
                Close
              </button>
              <button
                type="button"
                className="btn w-sm btn-danger "
                id="delete-record"
                onClick={() => handleExpireUrl()}
              >
                Yes, Expired It!
              </button>
            </div>
          </ModalBody>
        </Modal>
      </div>
    </>
  );
};

export default UrlListing;
