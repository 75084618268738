import "./LinkList.scss";
import AddIcon from "../../../../assets/images/New/Add.svg";
import CopyIcon from "../../../../assets/images/New/Copy.svg";
import DeleteIcon from "../../../../assets/images/New/Delete.svg";
import { useEffect, useState } from "react";
import useTranscriptionStore from "../../../../Store/TranscriptionStore/TranscriptionStore";
import {
  getUrlList,
  postCreateUploadUrl,
  postDeleteUrl,
  postUrlExpire,
} from "../../../../ApiService/ApiCall";
import { shortName } from "../../../../Common/helper/helper";
import dayjs from "dayjs";
import { toast } from "react-hot-toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Flatpickr from "react-flatpickr";
import { faCalendarDays } from "@fortawesome/free-solid-svg-icons";
import Pagination from "../../Common/Pagination/Pagination";
import NotAvailable from "../../Common/NotAvailable";
import DeleteModal from "../DeleteModal/Delete";
import useTabStore from "../../../../Store/TabStore/TabStore";
import { handleDivResize } from "../../../../Common/helper/util";
import { MESSAGE } from "../../../../Constants/Messages";

const LinkList = () => {
  const [skipCount, setSkipCount] = useState(0);
  const [urlList, setUrlList] = useState<any[]>([]);
  const [limit] = useState(10);
  const [expireDate, setExpireDate] = useState(dayjs().endOf("day").format());
  const [updateListStatus, setUpdateListStatus] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [selectedPage, setSelectedPage] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [showNotAvailable, setShowNotAvailable] = useState(false);
  const [showExpireConfirmationModal, setShowExpireConfirmationModal] =
    useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState("");
  const [divSize, setDivSize] = useState(false);

  const { setLoading } = useTranscriptionStore((state: any) => ({
    setLoading: state.setLoading,
  }));

  const { windowSmallSize, activeItems } = useTabStore((state: any) => ({
    windowSmallSize: state.windowSmallSize,
    activeItems: state.activeItems,
  }));

  useEffect(() => {
    const containerDiv = document.getElementById("link-list-section");
    const handleResize = () => {
      if (containerDiv && containerDiv?.offsetWidth < 765) {
        setDivSize(true);
      } else {
        setDivSize(false);
      }
    };
    containerDiv?.addEventListener("resize", handleResize);

    var resizeObserver = new ResizeObserver(handleResize);
    containerDiv && resizeObserver.observe(containerDiv);

    return () => {
      containerDiv?.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleResizeDiv = () => {
    const containerDiv = document.getElementById("link-list-section");
    handleDivResize(containerDiv, setDivSize);
  };

  useEffect(() => {
    const List = async () => {
      await sharedURLListing();
    };
    List();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateListStatus, skipCount]);

  const handleDateChange = (selectedDates: any) => {
    setExpireDate(dayjs(selectedDates).endOf("day").format());
  };

  const sharedURLListing = async () => {
    setLoading(true);
    const result: any = await getUrlList({
      skip: skipCount,
      limit: limit,
      searchStr: "",
      sortBy: "",
    });
    setUrlList(result?.result || []);
    setTotalCount(result?.totalCount);
    setPageCount(Math.ceil(result?.totalCount / limit));
    if (
      (result?.result?.length === 0 || result?.totalCount === 0) &&
      skipCount > 0
    ) {
      setSkipCount(skipCount - 10);
      setSelectedPage(selectedPage - 1);
    } else if (result?.result?.length === 0 || result?.totalCount === 0) {
      setShowNotAvailable(true);
    } else {
      setShowNotAvailable(false);
    }
    setLoading(false);
  };

  const handleCopy = (text: any, dateAndTime: string) => {
    if (checkLinkExpireOrNot(dateAndTime)) {
      navigator.clipboard
        .writeText(text)
        .then(() => toast.success(MESSAGE.SUCCESS.linkCopy))
        .catch((err) => toast.error(MESSAGE.ERROR.copyFail));
    }
  };

  const handleCreateUrl = async () => {
    try {
      setLoading(true);
      await postCreateUploadUrl({ date: expireDate });
      setUpdateListStatus(!updateListStatus);
      toast.success(MESSAGE.SUCCESS.linkGenerated);
      setExpireDate(dayjs().endOf("day").format());
      setLoading(false);
    } catch (err: any) {
      toast.error(err.msg);
      setLoading(false);
    }
  };

  const handlePageChange = (event: any) => {
    let temSkip = event.selected * limit;
    setSkipCount(temSkip);
    setSelectedPage(event.selected);
  };

  const openExpireConfirmationModal = (id: string, dateAndTime: string) => {
    if (checkLinkExpireOrNot(dateAndTime)) {
      setSelectedItem(id);
      setShowExpireConfirmationModal(true);
    }
  };

  const openDeleteConfirmationModal = (id: string) => {
    setSelectedItem(id);
    setShowDeleteModal(true);
  };

  const closeExpireConfirmationModal = () => {
    setShowExpireConfirmationModal(false);
  };

  const handleExpireConfirmation = async () => {
    try {
      await postUrlExpire({ id: selectedItem });
      toast.success(MESSAGE.SUCCESS.linkDeactivated);
      setUpdateListStatus(!updateListStatus);
    } catch (err: any) {
      toast.error(err.msg || "Something went wrong");
      console.error(err);
    }

    closeExpireConfirmationModal();
  };

  const handleDeleteClick = async () => {
    try {
      await postDeleteUrl({ id: selectedItem });
      toast.success(MESSAGE.SUCCESS.linkDeleted);
      setUpdateListStatus(!updateListStatus);
    } catch (err: any) {
      toast.error(err?.msg || "Something went wrong");
      console.error(err);
    }

    setShowDeleteModal(false);
  };

  const checkLinkExpireOrNot = (dateAndTime: string) => {
    const currentDate = dayjs();
    const dateToCompare = dayjs(dateAndTime);

    if (dateToCompare.isAfter(currentDate)) {
      return true;
    } else {
      return false;
    }
  };

  const handleIconClick = () => {
    const calenderRef = document.getElementById("expireDate");
    if (calenderRef) {
      calenderRef.click();
    }
  };

  return (
    <div
      className="link-list-section"
      id="link-list-section"
      onResize={handleResizeDiv}
    >
      <div className="action1">
        <div className="left" onClick={handleIconClick}>
          <Flatpickr
            id="expireDate"
            defaultValue={dayjs(expireDate).toISOString()}
            className="form-control search-filter-custom "
            placeholder="Select Expire Date"
            value={dayjs(expireDate).toISOString()}
            options={{
              mode: "single",
              enableTime: false,
              minDate: "today",
              dateFormat: "d-m-Y",
            }}
            onChange={handleDateChange}
          />
          <FontAwesomeIcon
            onClick={handleIconClick}
            className="mx-2 cursor-pointer"
            icon={faCalendarDays}
          />
        </div>
        <div className="right" onClick={handleCreateUrl}>
          {!windowSmallSize && !divSize ? "New link" : ""}
          <img src={AddIcon} alt="add" title="New Link" className="mx-2" />
        </div>
      </div>
      {urlList && urlList.length > 0 && (
        <div
          className={`url-list-outer ${
            activeItems.length > 3 ? "h-large" : "h-small"
          }`}
          id="url-list-outer"
        >
          {urlList &&
            urlList.length > 0 &&
            urlList.map((item: any, index: any) => {
              return (
                <div className="url-card" key={index}>
                  <div className="title">
                    <div>
                      {shortName(item.url, activeItems.length > 3 ? 15 : 25)}
                    </div>
                    <div className="expire">
                      {!item.isExpire ? "Expires" : "Expired"}{" "}
                      {dayjs(item.expireIn).format("DD.MM.YYYY")}
                    </div>
                  </div>
                  <div className="action-part">
                    {!item.isExpire && checkLinkExpireOrNot(item.expireIn) && (
                      <div
                        className="clickable"
                        title="expire"
                        onClick={() =>
                          openExpireConfirmationModal(item?._id, item.expireIn)
                        }
                      >
                        <i
                          style={{ fontSize: "18px", color: "#ced4da" }}
                          className="ri-time-fill"
                        ></i>
                      </div>
                    )}
                    <div
                      className="clickable"
                      title="delete"
                      onClick={() => openDeleteConfirmationModal(item?._id)}
                    >
                      <img src={DeleteIcon} alt="delete" />
                    </div>
                    {!item.isExpire && checkLinkExpireOrNot(item.expireIn) && (
                      <div className="clickable" title="copy">
                        <img
                          src={CopyIcon}
                          alt="copy"
                          onClick={() => handleCopy(item.url, item.expireIn)}
                        />
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
        </div>
      )}
      {urlList && urlList.length > 0 && (
        <Pagination
          list={urlList}
          totalCount={totalCount}
          limit={limit}
          skipCount={skipCount}
          pageCount={pageCount}
          selectedPage={selectedPage}
          handlePageChange={handlePageChange}
        />
      )}
      {showNotAvailable && (
        <NotAvailable margin={true} msg="Not Available"></NotAvailable>
      )}
      <DeleteModal
        show={showExpireConfirmationModal}
        onDeleteClick={handleExpireConfirmation}
        onCloseClick={closeExpireConfirmationModal}
        recordId={selectedItem}
        deleteType={true}
      />
      <DeleteModal
        show={showDeleteModal}
        onDeleteClick={handleDeleteClick}
        onCloseClick={() => setShowDeleteModal(false)}
        recordId={selectedItem}
        deleteType={false}
      />
    </div>
  );
};

export default LinkList;
