import { toast } from "react-hot-toast";
import { checkFileNameLength } from "../../../../Common/helper/helper";
import useFileUploadStore from "../../../../Store/UploadStore/uploadStore";
import { v4 as uid } from "uuid";
import { MESSAGE } from "../../../../Constants/Messages";

const UploadFileInfo = () => {
  const {
    files,
    setShowUploadStart,
    setShowUploadInfo,
    setUploadFailedStatus,
    setCurrentUploadFileId,
    filesDetails,
    setFileDetails,
    setUploadCancelStatus,
  } = useFileUploadStore((state: any) => ({
    files: state.files,
    setShowUploadStart: state.setShowUploadStart,
    setShowUploadInfo: state.setShowUploadInfo,
    setUploadFailedStatus: state.setUploadFailedStatus,
    setCurrentUploadFileId: state.setCurrentUploadFileId,
    filesDetails: state.filesDetails,
    setFileDetails: state.setFileDetails,
    setUploadCancelStatus: state.setUploadCancelStatus,
  }));
  const handleUpload = () => {
    if (checkFileNameLength(files[0]?.["name"])) {
      const tempFile: any[] = [...files];
      const tempFileDetails: any[] = [...filesDetails];
      const tempId = uid();
      tempFile[0].id = tempId;
      setCurrentUploadFileId(tempId);
      tempFile[0].temp = true;
      setFileDetails(tempFile);
      tempFileDetails.push(tempFile);
      setFileDetails(tempFileDetails);
      setUploadFailedStatus(false);
      setShowUploadInfo(false);
      setShowUploadStart(true);
      setUploadCancelStatus(false);
    } else {
      toast.error(MESSAGE.ERROR.fileRenameError);
    }
  };

  return (
    <div className="upload-file-info-outer">
      <div className="info-outer">
        <div className="file-name">
          {files && files.length > 0 && files[0]?.["name"]}
        </div>
        <div className="upload-btn" onClick={handleUpload}>
          Upload
        </div>
      </div>
    </div>
  );
};

export default UploadFileInfo;
