import { useEffect, useState } from "react";
import "./OperationList.scss";
import {
  getQueueResources,
  getTimeSpendData,
  postReProcessVideo,
} from "../../../../ApiService/ApiCall";
import useTranscriptionStore from "../../../../Store/TranscriptionStore/TranscriptionStore";
import {
  getExtension,
  getVideoStatus,
  getWhisperLangName,
  secondsToMinutes,
} from "../../../../Common/helper/helper";
import { VIDEO_STATUS } from "../../../../Constants/Common/Data";
import Pagination from "../../Common/Pagination/Pagination";
import { OperationListProps } from "../../../../Common/interface/types";
import TitleIcon from "./TitleIcon";
import useFileUploadStore from "../../../../Store/UploadStore/uploadStore";
import { toast } from "react-hot-toast";
import ReTranscribeModel from "../ReTranscribeModel/ReTranscribeModel";
import useUserStore from "../../../../Store/UserStore/UserStore";
import RetryIcon from "../../../../assets/images/New/RetryIcon.svg";
import { Input, Progress, Tooltip } from "reactstrap";
import CancelIcon from "../../../../assets/images/New/Cancel.svg";
import ReTranscribe from "../../../../assets/images/New/ReTranscribe.svg";
import CancelModal from "../CancelModal/CancelModal";
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import RadialSeparators from "../../Common/RadialSeperator";
import dayjs from "dayjs";
// import { MESSAGE } from "../../../../Constants/Messages";
import { uploadVideoDetailsDefaultValues } from "../../../../Common/interface/defaultValue";

const OperationList = (props: OperationListProps) => {
  const { updateOperationListStatus } = props;
  const [queueList, setQueueList] = useState<any[]>([]);
  const [skipCount, setSkipCount] = useState(0);
  const [limit] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [selectedPage, setSelectedPage] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [showLanguageModal, setShowLanguageModal] = useState(false);
  const [selectedResourceId, setSelectedResourceId] = useState("");
  const [showReprocessBtn, setShowReprocessBtn] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [isApiCall, setIsApiCall] = useState(false);
  const [isDetailsChecked, setIsDetailsChecked] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [compressPercentage, setCompressPercentage] = useState(0);
  const [uploadVideoDetails, setUploadVideoDetails] = useState(
    uploadVideoDetailsDefaultValues
  );
  const [videoDetails, setVideoDetails] = useState(
    uploadVideoDetailsDefaultValues
  );

  const [timeState, setTimeState] = useState(0);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggleTooltip = () => {
    setTooltipOpen(!tooltipOpen);
  };

  const {
    setLoading,
    transcriptionVideoList,
    setTranscriptionList,
    selectedVideoId,
    setLeftSubtitleData,
    setRightSubtitleData,
  } = useTranscriptionStore((state: any) => ({
    setLoading: state.setLoading,
    transcriptionVideoList: state.transcriptionVideoList,
    setTranscriptionList: state.setTranscriptionList,
    selectedVideoId: state.selectedVideoId,
    setLeftSubtitleData: state.setLeftSubtitleData,
    setRightSubtitleData: state.setRightSubtitleData,
  }));

  const {
    uploadStatus,
    files,
    uploadedPercentage,
    setCancelModalStatus,
    uploadFailedStatus,
    setUploadFailedStatus,
    setShowUploadStart,
    currentUploadFileId,
    uploadedVideoDetails,
    setUploadedVideoDetails,
    uploadCancelStatus,
  } = useFileUploadStore((state: any) => ({
    uploadStatus: state.uploadStatus,
    files: state.files,
    uploadedPercentage: state.uploadedPercentage,
    setCancelModalStatus: state.setCancelModalStatus,
    uploadFailedStatus: state.uploadFailedStatus,
    setUploadFailedStatus: state.setUploadFailedStatus,
    setShowUploadStart: state.setShowUploadStart,
    currentUploadFileId: state.currentUploadFileId,
    uploadedVideoDetails: state.uploadedVideoDetails,
    setUploadedVideoDetails: state.setUploadedVideoDetails,
    uploadCancelStatus: state.uploadCancelStatus,
  }));

  const { currentUser } = useUserStore((state: any) => ({
    currentUser: state.currentUser,
  }));

  useEffect(() => {
    const configSetting = currentUser?.configSetting;
    if (currentUser && configSetting) {
      setShowReprocessBtn(currentUser?.configSetting?.isReprocessAllow);
    }
  }, [currentUser]);

  useEffect(() => {
    const getList = async () => {
      await getQueueList();
    };

    getList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skipCount, updateOperationListStatus]);

  const getQueueList = async () => {
    setLoading(true);
    try {
      const queueResult: any = await getQueueResources({
        skip: skipCount,
        limit: limit,
        searchStr: "",
        sortBy: "",
      });

      setQueueList(queueResult.result);
      setTotalCount(queueResult.total || 0);
      setPageCount(Math.ceil(queueResult.total / limit) || 1);
    } catch (error) {}
    setLoading(false);
  };

  useEffect(() => {
    let updatedQueueList: any[] = [];
    if (
      uploadStatus &&
      files.length > 0 &&
      !queueList.some((item) => item._id === currentUploadFileId) &&
      uploadedPercentage < 100
    ) {
      const newUploadItem = {
        _id: currentUploadFileId,
        title: files[0]?.name,
        status: VIDEO_STATUS.TEMP_UPLOAD,
      };
      updatedQueueList = [newUploadItem, ...queueList];
      setUploadVideoDetails(uploadVideoDetailsDefaultValues);
      setCompressPercentage(0);
      setIsDetailsChecked(false);
      setSelectedId("");
      setQueueList(updatedQueueList);
    }
    if (
      uploadStatus &&
      files.length > 0 &&
      !queueList.some((item) => item._id === currentUploadFileId) &&
      uploadedPercentage === 100
    ) {
      const newUploadItem = {
        _id: currentUploadFileId,
        title: files[0]?.name,
        status: VIDEO_STATUS.COMPRESS,
      };
      updatedQueueList = [newUploadItem, ...queueList];
      setQueueList(updatedQueueList);
    }
    if (
      uploadStatus &&
      files.length > 0 &&
      queueList.some(
        (item) =>
          item._id === currentUploadFileId &&
          item.status === VIDEO_STATUS.TEMP_UPLOAD
      ) &&
      uploadedPercentage === 100
    ) {
      const updatedList = queueList.map((item) => {
        if (item._id === currentUploadFileId) {
          item.status = VIDEO_STATUS.COMPRESS;
        }
        return item;
      });
      setQueueList(updatedList);
    }
    if (
      !uploadStatus &&
      uploadFailedStatus &&
      files.length > 0 &&
      queueList.some(
        (item) =>
          item._id === currentUploadFileId &&
          item.status === VIDEO_STATUS.TEMP_UPLOAD
      )
    ) {
      const updatedList = queueList.map((item) => {
        if (item._id === currentUploadFileId) {
          item.status = VIDEO_STATUS.UPLOAD_FAILED;
        }
        return item;
      });
      setQueueList(updatedList);
    }
    if (
      uploadStatus &&
      !uploadFailedStatus &&
      files.length > 0 &&
      queueList.some(
        (item) =>
          item._id !== currentUploadFileId &&
          item.status === VIDEO_STATUS.UPLOAD_FAILED
      )
    ) {
      const updatedList = queueList.filter((_item) => {
        return _item.status !== VIDEO_STATUS.UPLOAD_FAILED;
      });
      setQueueList(updatedList);
    }
    if (
      !uploadStatus &&
      uploadCancelStatus &&
      files.length === 0 &&
      queueList.some((item) => item._id === currentUploadFileId)
    ) {
      const updatedList = queueList.filter((_item) => {
        return _item._id !== currentUploadFileId;
      });
      setQueueList(updatedList);
    }
    if (
      !uploadStatus &&
      !uploadFailedStatus &&
      files.length === 0 &&
      queueList.some(
        (item) =>
          item._id === currentUploadFileId &&
          item.status !== VIDEO_STATUS.UPLOADED
      )
    ) {
      const updatedList = queueList.map((item) => {
        if (item._id === currentUploadFileId) {
          item.status = VIDEO_STATUS.UPLOADED;
        }
        return item;
      });
      const tempDetails: any[] = [];
      const tempUploadedVideoDetails: any[] = [];
      tempDetails.push(uploadVideoDetails);
      tempDetails[0].id = currentUploadFileId;
      tempDetails[0].compressedDetail.percentage = 100;
      tempDetails[0].uploadDetail.date = new Date().toISOString();
      tempUploadedVideoDetails.push(...tempDetails, ...uploadedVideoDetails);
      setUploadedVideoDetails(tempUploadedVideoDetails);
      setQueueList(updatedList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadStatus, queueList, uploadedPercentage, uploadFailedStatus]);

  useEffect(() => {
    let timer: any;
    if (
      uploadStatus &&
      files.length > 0 &&
      queueList.some((item) => item._id === currentUploadFileId)
    ) {
      if (uploadedPercentage < 100) {
        setUploadVideoDetails((prevState) => ({
          ...prevState,
          uploadDetail: {
            ...prevState.uploadDetail,
            time: prevState.uploadDetail.time + 1,
            percentage: uploadedPercentage,
            date: new Date().toISOString(),
            uploadedBy: currentUser?.firstName + currentUser?.lastName,
          },
        }));
      }
      if (uploadedPercentage === 100) {
        setUploadVideoDetails((prevState) => ({
          ...prevState,
          uploadDetail: {
            ...prevState.uploadDetail,
            percentage: 100,
            uploadedBy: currentUser?.firstName,
            date: new Date().toISOString(),
          },
          compressedDetail: {
            ...prevState.compressedDetail,
            percentage:
              prevState.compressedDetail.time < 85
                ? prevState.compressedDetail.time + 1
                : 85,
            time: prevState.compressedDetail.time + 1,
            date: new Date().toISOString(),
            originalFileType: getExtension(files[0]?.name),
            compressedFileType: "mp4",
            format: "libx264",
          },
        }));
      }
      timer = setTimeout(() => {
        setTimeState((prevState) => {
          return prevState + 1;
        });
      }, 1000);
    }

    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadStatus, queueList, timeState]);

  useEffect(() => {
    let timer: any;
    if (
      uploadStatus &&
      files.length > 0 &&
      queueList.some(
        (item) =>
          item._id === currentUploadFileId &&
          item.status === VIDEO_STATUS.COMPRESS
      )
    ) {
      timer = setInterval(() => {
        setCompressPercentage((prevState) => {
          return prevState < 85 ? prevState + 1 : 85;
        });
      }, 1000);
    }
    return () => clearInterval(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadStatus, uploadedPercentage]);

  const handlePageChange = (event: any) => {
    const temSkip = event.selected * limit;
    setSkipCount(temSkip);
    setSelectedPage(event.selected);
  };

  const checkVideoTempOrNot = (id: string) => {
    const tempVideoDetails = uploadedVideoDetails.filter(
      (item: any) => item.id === id
    );
    if (tempVideoDetails && tempVideoDetails.length === 1) {
      setVideoDetails((prevState) => {
        return tempVideoDetails[0];
      });
      return true;
    } else return false;
  };

  const handleDetailsCheck = async (id: string) => {
    if (selectedId === id) {
      setIsDetailsChecked(false);
      setSelectedId("");
    } else if (id === currentUploadFileId) {
      setSelectedId(id);
      setIsDetailsChecked(true);
    } else if (checkVideoTempOrNot(id)) {
      setSelectedId(id);
      setIsDetailsChecked(true);
    } else {
      const res: any = await getVideoDetails(id);
      setVideoDetails(res);
      setSelectedId(id);
      setIsDetailsChecked(true);
    }
  };

  const getVideoDetails = async (id: string) => {
    try {
      const res = await getTimeSpendData({ resourceId: id });
      return res;
    } catch (err: any) {
      console.log(err);
      toast.error(err?.msg);
    }
  };

  const handleReTranscribe = async (id: string) => {
    setIsApiCall(false);
    setSelectedResourceId(id);
    setShowLanguageModal(true);
  };

  const handleProcess = async (lang: any) => {
    setLoading(true);
    // setIsApiCall(true);
    await callReProcessApi(selectedResourceId, lang.value);
    await getQueueList();
    updateResourceList(selectedResourceId, lang.value);
    setLoading(false);
  };

  const callReProcessApi = async (resourceId: string, lang: string) => {
    try {
      let res: any = await postReProcessVideo({
        id: resourceId,
        language: lang.toLowerCase(),
      });
      setIsApiCall(true);
      // toast.success(MESSAGE.SUCCESS.videoStatusChanges);
      return res;
    } catch (err: any) {
      toast.error(err);
    }
  };

  const updateResourceList = (resourceId: string, lang: string) => {
    let videoList = transcriptionVideoList.map((_data: any) => {
      if (_data._id === resourceId) {
        _data.status = VIDEO_STATUS.REPROCESS_VIDEO;
        _data.defaultLanguage[0] = lang;
      }
      return _data;
    });
    if (resourceId === selectedVideoId) {
      setLeftSubtitleData("");
      setRightSubtitleData("");
    }
    setTranscriptionList(videoList);
  };

  const handleCancelVideoUpload = () => {
    setCancelModalStatus(true);
  };

  const handleRetry = () => {
    setCompressPercentage(0);
    setUploadFailedStatus(false);
    if (
      files.length > 0 &&
      queueList.some((item) => item._id === currentUploadFileId)
    ) {
      const updatedList = queueList.map((item) => {
        if (item._id === currentUploadFileId) {
          item.status = VIDEO_STATUS.TEMP_UPLOAD;
        }
        return item;
      });
      setQueueList(updatedList);
    }
    setShowUploadStart(true);
  };

  return (
    <>
      <div className="operation-list-outer">
        {queueList &&
          queueList.length > 0 &&
          queueList.map((_item, index) => (
            <>
              <div className="operation-list-card" key={`key-${index}`}>
                <div className="title-outer">
                  <div className="title">
                    <div key={index}>
                      <TitleIcon key={index} index={index} item={_item} />
                    </div>
                  </div>
                  <div className="operation-action">
                    {(_item?.status === VIDEO_STATUS.TEMP_UPLOAD ||
                      _item?.status === VIDEO_STATUS.COMPRESS ||
                      _item?.status === VIDEO_STATUS.UPLOAD_FAILED) &&
                      uploadFailedStatus && (
                        <div className="retry-action" onClick={handleRetry}>
                          <img src={RetryIcon} alt="retry" />
                          <div>Retry</div>
                        </div>
                      )}
                    {(_item?.status === VIDEO_STATUS.COMPLETED ||
                      _item?.status === VIDEO_STATUS.REPROCESS_VIDEO_ERROR ||
                      _item?.status === VIDEO_STATUS.NOT_PROCESSED ||
                      _item?.status ===
                        VIDEO_STATUS.REPROCESS_VIDEO_COMPLETED) &&
                      showReprocessBtn && (
                        <div
                          className="retry-action"
                          onClick={() => handleReTranscribe(_item?._id)}
                        >
                          <img src={ReTranscribe} alt="ReTranscribe" />
                          <div>Transcribe</div>
                        </div>
                      )}

                    <div className="retry-action">
                      <div>Details</div>
                      <div className="form-check form-switch">
                        <Input
                          className="form-check-input"
                          type="checkbox"
                          key={index}
                          checked={
                            selectedId &&
                            selectedId === _item?._id &&
                            isDetailsChecked
                              ? true
                              : false
                          }
                          onChange={() => handleDetailsCheck(_item?._id)}
                          role="switch"
                          id={`Check-${index}`}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {selectedId !== _item?._id && (
                  <>
                    {/* upload */}
                    {_item?.status === VIDEO_STATUS.TEMP_UPLOAD &&
                      !uploadFailedStatus && (
                        <div className="progress-outer">
                          <Progress
                            value={
                              _item?._id === currentUploadFileId
                                ? uploadedPercentage
                                : 100
                            }
                            className="upload progress-xl"
                          ></Progress>
                          <div className="progress-title">
                            UPLOADING{" "}
                            {_item?._id === currentUploadFileId &&
                            uploadedPercentage
                              ? `${uploadedPercentage}%`
                              : ""}
                          </div>
                          <div className="progress-action">
                            {_item?._id === currentUploadFileId && (
                              <img
                                src={CancelIcon}
                                alt="cancel"
                                className="cursor-pointer"
                                style={{ marginTop: "2px" }}
                                onClick={handleCancelVideoUpload}
                              />
                            )}
                          </div>
                        </div>
                      )}

                    {/* compress */}
                    {(_item?.status === VIDEO_STATUS.COMPRESS ||
                      _item?.status === VIDEO_STATUS.UPLOADED) &&
                      !uploadFailedStatus && (
                        <div className="progress-outer">
                          <Progress
                            value={
                              _item?.status === VIDEO_STATUS.UPLOADED
                                ? 100
                                : compressPercentage
                            }
                            className="compression progress-xl"
                          ></Progress>
                          <div className="progress-title">
                            {_item?.status === VIDEO_STATUS?.UPLOADED &&
                              "COMPRESSION SUCCESSFUL"}
                            {_item?.status === VIDEO_STATUS?.COMPRESS &&
                              `COMPRESSION STARTING ${
                                _item?.status === VIDEO_STATUS.UPLOADED
                                  ? 100
                                  : `${compressPercentage}%`
                              }`}
                          </div>
                          <div className="progress-action">
                            {_item?.status === VIDEO_STATUS.COMPRESS && (
                              <img
                                src={CancelIcon}
                                alt="cancel"
                                className="cursor-pointer"
                                style={{ marginTop: "2px" }}
                                onClick={handleCancelVideoUpload}
                              />
                            )}
                          </div>
                        </div>
                      )}

                    {/* translation */}
                    {_item?.status === VIDEO_STATUS.PROCESSING && (
                      <div className="progress-outer">
                        <Progress
                          value={0}
                          className="translation progress-xl"
                        ></Progress>
                        <div className="progress-title">TRANSCRIPTING</div>
                        <div className="progress-action"></div>
                      </div>
                    )}

                    {(_item?.status === VIDEO_STATUS.COMPLETED ||
                      _item?.status ===
                        VIDEO_STATUS.REPROCESS_VIDEO_COMPLETED ||
                      _item.status === VIDEO_STATUS.REPROCESS_VIDEO) && (
                      <div className="progress-outer">
                        <Progress
                          value={
                            _item?.status === VIDEO_STATUS.COMPLETED
                              ? 100
                              : _item?.status ===
                                VIDEO_STATUS.REPROCESS_VIDEO_COMPLETED
                              ? 100
                              : _item?.status === VIDEO_STATUS.REPROCESS_VIDEO
                              ? 0
                              : 0
                          }
                          className="translation progress-xl"
                        ></Progress>
                        <div className="progress-title">
                          {_item?.status === VIDEO_STATUS.COMPLETED &&
                            `TRANSCRIPTION SUCCESSFUL: ${
                              _item?.language
                                ? getWhisperLangName(_item?.language)
                                : ""
                            }`}
                          {_item?.status ===
                            VIDEO_STATUS.REPROCESS_VIDEO_COMPLETED &&
                            `TRANSCRIPTION REPROCESSED: ${
                              _item?.language
                                ? getWhisperLangName(_item?.language)
                                : ""
                            }`}
                          {_item?.status === VIDEO_STATUS.REPROCESS_VIDEO &&
                            `TRANSCRIPTION REPROCESSING: ${
                              _item?.language
                                ? getWhisperLangName(_item?.language)
                                : ""
                            }`}
                        </div>
                      </div>
                    )}

                    {(_item?.status === VIDEO_STATUS.NOT_PROCESSED ||
                      _item?.status === VIDEO_STATUS.UPLOAD_FAILED) && (
                      <div className="progress-outer">
                        <Progress
                          value={100}
                          className="translation-failed progress-xl"
                        ></Progress>
                        <div className="progress-title">
                          {_item?.status === VIDEO_STATUS.NOT_PROCESSED &&
                            "TRANSCRIPTION FAILED"}
                          {_item?.status === VIDEO_STATUS.UPLOAD_FAILED &&
                            uploadFailedStatus &&
                            "UPLOAD FAILED"}
                        </div>
                      </div>
                    )}
                  </>
                )}
                {selectedId &&
                  selectedId === _item?._id &&
                  isDetailsChecked && (
                    <div className="details-info">
                      {(_item?.status === VIDEO_STATUS.UPLOADED ||
                        _item?.status === VIDEO_STATUS.TEMP_UPLOAD ||
                        _item?.status === VIDEO_STATUS.COMPRESS ||
                        _item?.status === VIDEO_STATUS.PROCESSING ||
                        _item?.status === VIDEO_STATUS.COMPLETED ||
                        _item?.status === VIDEO_STATUS.REPROCESS_VIDEO ||
                        _item?.status === VIDEO_STATUS.NOT_PROCESSED ||
                        _item?.status ===
                          VIDEO_STATUS.REPROCESS_VIDEO_COMPLETED) && (
                        <div className="card-inner">
                          <div className="title">UPLOAD</div>
                          <div className="loader">
                            <CircularProgressbarWithChildren
                              value={
                                selectedId === currentUploadFileId
                                  ? uploadVideoDetails?.uploadDetail?.percentage
                                  : videoDetails?.uploadDetail?.percentage
                              }
                              text={`${
                                selectedId === currentUploadFileId
                                  ? uploadVideoDetails?.uploadDetail?.percentage
                                  : videoDetails?.uploadDetail?.percentage
                              }%`}
                              strokeWidth={10}
                              styles={buildStyles({
                                strokeLinecap: "butt",
                                pathColor: "#A546EF",
                                textColor: "#A546EF",
                              })}
                            >
                              <RadialSeparators
                                count={10}
                                style={{
                                  background: "black",
                                  width: "1px",
                                  height: `${10}%`,
                                }}
                              />
                            </CircularProgressbarWithChildren>
                          </div>
                          <div className="info">
                            <div>
                              Time:{" "}
                              {secondsToMinutes(
                                selectedId === currentUploadFileId
                                  ? uploadVideoDetails?.uploadDetail?.time
                                  : videoDetails?.uploadDetail?.time
                              )}
                            </div>
                            <div>
                              Date:{" "}
                              {dayjs(
                                selectedId === currentUploadFileId
                                  ? uploadVideoDetails?.uploadDetail?.date
                                  : videoDetails?.uploadDetail?.date
                              ).format("DD-MM-YY")}
                            </div>
                            <div className="user-name">
                              Upload by:{" "}
                              <span id={`user-name-${index}`}>
                                {selectedId === currentUploadFileId
                                  ? uploadVideoDetails?.uploadDetail?.uploadedBy
                                  : videoDetails?.uploadDetail?.uploadedBy}
                              </span>
                              <Tooltip
                                placement="top"
                                isOpen={tooltipOpen}
                                autohide={true}
                                target={`user-name-${index}`}
                                toggle={toggleTooltip}
                              >
                                {selectedId === currentUploadFileId
                                  ? uploadVideoDetails?.uploadDetail?.uploadedBy
                                  : videoDetails?.uploadDetail?.uploadedBy}
                              </Tooltip>
                            </div>
                          </div>
                        </div>
                      )}
                      {_item?.status === VIDEO_STATUS.UPLOAD_FAILED && (
                        <div className="card-inner">
                          <div className="title">UPLOAD</div>
                          <div className="loader">
                            <CircularProgressbarWithChildren
                              value={100}
                              strokeWidth={10}
                              styles={buildStyles({
                                strokeLinecap: "butt",
                                pathColor: "#e9001c",
                                textColor: "#A546EF",
                              })}
                            >
                              <RadialSeparators
                                count={10}
                                style={{
                                  background: "black",
                                  width: "1px",
                                  height: `${10}%`,
                                }}
                              />
                            </CircularProgressbarWithChildren>
                          </div>
                          <div className="info">
                            <div>
                              Time:{" "}
                              {secondsToMinutes(
                                selectedId === currentUploadFileId
                                  ? uploadVideoDetails?.uploadDetail?.time
                                  : videoDetails?.uploadDetail?.time
                              )}
                            </div>
                            <div>
                              Date:{" "}
                              {dayjs(
                                selectedId === currentUploadFileId
                                  ? uploadVideoDetails?.uploadDetail?.date
                                  : videoDetails?.uploadDetail?.date
                              ).format("DD-MM-YY")}
                            </div>
                            <div>
                              Upload by:{" "}
                              {selectedId === currentUploadFileId
                                ? uploadVideoDetails?.uploadDetail?.uploadedBy
                                : videoDetails?.uploadDetail?.uploadedBy}
                            </div>
                            {_item?.status === VIDEO_STATUS.UPLOAD_FAILED && (
                              <div>Status: Failed</div>
                            )}
                          </div>
                        </div>
                      )}
                      {(_item?.status === VIDEO_STATUS.COMPRESS ||
                        _item?.status === VIDEO_STATUS.UPLOADED ||
                        _item?.status === VIDEO_STATUS.PROCESSING ||
                        _item?.status === VIDEO_STATUS.COMPLETED ||
                        _item?.status === VIDEO_STATUS.NOT_PROCESSED ||
                        _item?.status === VIDEO_STATUS.REPROCESS_VIDEO ||
                        _item?.status ===
                          VIDEO_STATUS.REPROCESS_VIDEO_COMPLETED) && (
                        <div className="card-inner">
                          <div className="title">COMPRESSION</div>
                          <div className="loader">
                            <CircularProgressbarWithChildren
                              value={
                                selectedId === currentUploadFileId
                                  ? uploadVideoDetails?.compressedDetail
                                      ?.percentage
                                  : videoDetails?.compressedDetail?.percentage
                              }
                              text={`${
                                selectedId === currentUploadFileId
                                  ? uploadVideoDetails?.compressedDetail
                                      ?.percentage
                                  : videoDetails?.compressedDetail?.percentage
                              }%`}
                              strokeWidth={10}
                              styles={buildStyles({
                                strokeLinecap: "butt",
                                pathColor: "#16A799",
                                textColor: "#16A799",
                              })}
                            >
                              <RadialSeparators
                                count={10}
                                style={{
                                  background: "black",
                                  width: "1px",
                                  height: `${10}%`,
                                }}
                              />
                            </CircularProgressbarWithChildren>
                          </div>
                          <div className="info">
                            <div>
                              Time:{" "}
                              {secondsToMinutes(
                                selectedId === currentUploadFileId
                                  ? uploadVideoDetails?.compressedDetail?.time
                                  : videoDetails?.compressedDetail?.time
                              )}
                            </div>
                            <div>
                              Date:{" "}
                              {dayjs(
                                selectedId === currentUploadFileId
                                  ? uploadVideoDetails?.compressedDetail?.date
                                  : videoDetails?.compressedDetail?.date
                              ).format("DD/MM/YY")}
                            </div>
                            <div>
                              Format:{" "}
                              {selectedId === currentUploadFileId
                                ? `${
                                    uploadVideoDetails?.compressedDetail
                                      ?.originalFileType
                                      ? uploadVideoDetails?.compressedDetail
                                          ?.originalFileType
                                      : "-"
                                  } : ${
                                    uploadVideoDetails?.compressedDetail
                                      ?.compressedFileType
                                      ? uploadVideoDetails?.compressedDetail
                                          ?.compressedFileType
                                      : "-"
                                  }`
                                : `${
                                    videoDetails?.compressedDetail
                                      ?.originalFileType
                                      ? videoDetails?.compressedDetail
                                          ?.originalFileType
                                      : "-"
                                  } : ${
                                    videoDetails?.compressedDetail
                                      ?.compressedFileType
                                      ? videoDetails?.compressedDetail
                                          ?.compressedFileType
                                      : "-"
                                  }`}
                            </div>
                          </div>
                        </div>
                      )}
                      {(_item?.status === VIDEO_STATUS.PROCESSING ||
                        _item?.status === VIDEO_STATUS.COMPLETED ||
                        _item?.status === VIDEO_STATUS.NOT_PROCESSED ||
                        _item?.status === VIDEO_STATUS.REPROCESS_VIDEO ||
                        _item?.status ===
                          VIDEO_STATUS.REPROCESS_VIDEO_COMPLETED) && (
                        <div className="card-inner">
                          <div className="title">TRANSCRIPTION</div>
                          <div className="loader">
                            <CircularProgressbarWithChildren
                              value={
                                videoDetails?.transcriptionDetail?.percentage
                              }
                              text={`${videoDetails?.transcriptionDetail?.percentage}%`}
                              strokeWidth={10}
                              styles={buildStyles({
                                strokeLinecap: "butt",
                                pathColor: "#E4CD55",
                                textColor: "#E4CD55",
                              })}
                            >
                              <RadialSeparators
                                count={10}
                                style={{
                                  background: "black",
                                  width: "1px",
                                  height: `${10}%`,
                                }}
                              />
                            </CircularProgressbarWithChildren>
                          </div>
                          <div className="info">
                            <div>
                              Time:{" "}
                              {secondsToMinutes(
                                videoDetails?.transcriptionDetail?.time
                              )}
                            </div>
                            <div>
                              Date:{" "}
                              {dayjs(
                                videoDetails?.transcriptionDetail?.date
                              ).format("DD/MM/YY")}
                            </div>
                            {videoDetails?.transcriptionDetail
                              ?.autoDetectLanguage ? (
                              <div>
                                Auto-language:{" "}
                                {getWhisperLangName(
                                  videoDetails?.transcriptionDetail
                                    ?.autoDetectLanguage
                                )}
                              </div>
                            ) : (
                              <div>
                                Status:{" "}
                                {getVideoStatus(
                                  videoDetails?.transcriptionDetail?.status
                                )}
                              </div>
                            )}
                            {videoDetails?.transcriptionDetail
                              ?.reprocessedLang && (
                              <>
                                <div>
                                  Reprocessing:{" "}
                                  {getWhisperLangName(
                                    videoDetails?.transcriptionDetail
                                      ?.reprocessedLang
                                  )}
                                </div>
                                <div>
                                  {videoDetails?.transcriptionDetail
                                    ?.reprocessedCreatedAt && (
                                    <>
                                      {dayjs(
                                        videoDetails?.transcriptionDetail
                                          ?.reprocessedCreatedAt
                                      ).format("DD/MM/YY")}
                                    </>
                                  )}
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  )}
              </div>
              {currentUploadFileId === _item?._id && (
                <div className="custom-border"></div>
              )}
            </>
          ))}
      </div>
      {queueList && queueList.length > 0 && (
        <Pagination
          list={queueList}
          totalCount={totalCount}
          limit={limit}
          skipCount={skipCount}
          pageCount={pageCount}
          selectedPage={selectedPage}
          handlePageChange={handlePageChange}
        />
      )}
      <ReTranscribeModel
        show={showLanguageModal}
        isApiCall={isApiCall}
        onCloseClick={() => {
          setShowLanguageModal(false);
        }}
        onSubmitClick={(lang) => handleProcess(lang)}
      />
      <CancelModal
        title="transcription"
        show={showCancelModal}
        onCloseClick={() => setShowCancelModal(false)}
        onSubmitClick={() => setShowCancelModal(false)}
      />
    </>
  );
};

export default OperationList;
