import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import "./ShareModal.scss";
import Select from "react-select";
import { ShareModalProps } from "../../../../Common/interface/types";
import VideoCard from "./VideoCard";
import { useEffect, useState } from "react";
import { convertDropDownDataToArray } from "../../../../Common/helper/helper";
import { getUserList, postShareResource } from "../../../../ApiService/ApiCall";
import { toast } from "react-hot-toast";
import SpinnerLoader from "../../../Common/Spinner/Spinner";
import CloseIcon from "../../../../assets/images/New/close.svg";
import useTranscriptionStore from "../../../../Store/TranscriptionStore/TranscriptionStore";
import { VIDEO_CARD_TYPE } from "../../../../Constants/Common/Data";
import { MESSAGE } from "../../../../Constants/Messages";

const ShareModal = (props: ShareModalProps) => {
  const { modalStatus, handleModal, selectedResourceInfo } = props;

  const { selectedResourceId, setSelectedResourceId, setSelectedResourceInfo } =
    useTranscriptionStore((state: any) => ({
      selectedResourceId: state.selectedResourceId,
      setSelectedResourceId: state.setSelectedResourceId,
      setSelectedResourceInfo: state.setSelectedResourceInfo,
    }));
  const [selectedUsers, setSelectedUsers] = useState<any>({});
  const [userList, setUserList] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isValidationPassed, setIsValidationPassed] = useState(false);

  useEffect(() => {
    getUserListApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUserListApi = async () => {
    try {
      setIsLoading(true);
      const res: any = await getUserList({});
      setDropDownData(res?.result);
      setIsLoading(false);
    } catch (err: any) {
      setIsLoading(false);
      console.log("Error fetching data:", err);
    }
  };

  const setDropDownData = (list: any[]) => {
    let temp: any[] = [];
    list.forEach((_list: any) => {
      temp.push({
        value: _list?._id,
        label: `${
          _list?.firstName + " " + _list?.lastName + " (" + _list?.email + ")"
        }`,
      });
    });
    setUserList(temp);
  };

  const handleAddUser = (selectedUser: any, videoId: string) => {
    setSelectedUsers((prevSelectedUsers: any) => ({
      ...prevSelectedUsers,
      [videoId]: selectedUser,
    }));
  };

  const validateSelection = () => {
    const isValid =
      Object.values(selectedUsers).every(
        (user: any) => user && user.length > 0
      ) && selectedResourceInfo.length === createOutputArray().length;
    setIsValidationPassed(!isValid);
    return isValid;
  };

  const handleSubmit = () => {
    if (validateSelection()) {
      shareResourceApi();
    }
  };

  const shareResourceApi = async () => {
    try {
      setIsLoading(true);
      await postShareResource({
        data: createOutputArray(),
      });
      toast.success(MESSAGE.SUCCESS.shared);
      setIsLoading(false);
      handleModalClose();
    } catch (err: any) {
      toast.error(err?.msg || "Something went wrong");
      setIsLoading(false);
      console.log(err);
    }
  };

  const createOutputArray = () => {
    const outputArray: any = [];

    selectedResourceInfo.forEach((_data: any) => {
      const videoId = _data?._id;
      const selectedUser = selectedUsers[videoId];

      if (selectedUser) {
        outputArray.push({
          resourceId: videoId,
          userIds: convertDropDownDataToArray(selectedUser),
        });
      }
    });

    return outputArray;
  };

  const handleDelete = (id: string) => {
    const tempId = selectedResourceId.filter((_id: string) => _id !== id);
    const tempInfo = selectedResourceInfo.filter(
      (_data: any) => _data?._id !== id
    );
    setSelectedResourceId(tempId);
    setSelectedResourceInfo(tempInfo);
    if (tempId.length === 0) {
      setSelectedResourceId([]);
      setSelectedResourceInfo([]);
      handleModalClose();
    }
  };

  const handleModalClose = () => {
    setSelectedUsers({});
    setSelectedResourceId([]);
    setSelectedResourceInfo([]);
    setIsValidationPassed(false);
    handleModal();
  };

  return (
    <>
      {isLoading ? <SpinnerLoader /> : ""}
      <Modal
        id="shareModal"
        isOpen={modalStatus}
        toggle={() => handleModalClose()}
        className="share-modal"
        centered
        size="xl"
      >
        <ModalHeader>
          <h5 className="modal-title" id="myModalLabel">
            Share
          </h5>
          <Button
            type="button"
            className="btn-close"
            onClick={() => handleModalClose()}
            aria-label="Close"
          ></Button>
        </ModalHeader>
        <ModalBody>
          <div className="resource-list">
            {selectedResourceInfo &&
              selectedResourceInfo.length > 0 &&
              selectedResourceInfo.map((_data: any, index) => {
                return (
                  <>
                    <Row className="video-list-item" key={index}>
                      <Col xl={4}>
                        <VideoCard
                          key={_data?._id}
                          id={_data?._id}
                          title={_data?.title}
                          date={_data?.date}
                          size={_data?.size}
                          user={_data?.user}
                          thumbnail={_data?.thumbnail}
                          type={VIDEO_CARD_TYPE.SHARE}
                        />
                      </Col>
                      <Col className="user-list-outer">
                        <div className="user-list-inner">
                          <Select
                            className="user-list"
                            onChange={(selectedUser: any) =>
                              handleAddUser(selectedUser, _data?._id)
                            }
                            value={selectedUsers[_data?._id]}
                            options={userList}
                            maxMenuHeight={100}
                            placeholder="Select from the list or search..."
                            isMulti
                          />
                          <img
                            className="cursor-pointer"
                            onClick={() => handleDelete(_data?._id)}
                            src={CloseIcon}
                            title="Remove"
                            alt="delete"
                          />
                        </div>
                      </Col>
                    </Row>
                  </>
                );
              })}
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="error">
            {isValidationPassed && "Please select a user for each video"}
          </div>
          <Button color="light" onClick={() => handleModalClose()}>
            Close
          </Button>
          <Button color="primary" onClick={() => handleSubmit()}>
            Share
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ShareModal;
