import '../assets/scss/login.scss'
import { Input, Button, Form, FormFeedback, Spinner } from 'reactstrap';
import * as Yup from "yup";
import { useFormik } from "formik";
import Arrow from '../assets/images/New/login/Arrow.svg'
import File from '../assets/images/New/login/File.svg'
import Search from '../assets/images/New/login/Search.svg'
import Share from '../assets/images/New/login/Share.svg'
import Subtitle from '../assets/images/New/login/Subtitle.svg'
import Video from '../assets/images/New/login/Video.svg'
import Wave from '../assets/images/New/login/Wave.svg'
import LoginArrowIcon from '../assets/images/New/login/Login.svg'
import { getInstance } from '../supabaseClient';
import { toast } from 'react-hot-toast';
import { useState } from 'react';

const Login = () => {
  const [loading, setLoading] = useState(false);
  const [showVerify, setShowVerify] = useState(false)

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      email: ''
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
    }),
    onSubmit: (values) => {
      signIn(values)
    }
  });

  const signIn = async (user: any) => {
    setLoading(true)
    await callApi(user)
    setLoading(false)
  };

  const callApi = async (user: any) => {
    const { data, error } = await getInstance().auth.signInWithOtp({ email: user.email });
    if (data && error === null) {
      setShowVerify(true)
    }
    if (error) {
      toast.error(error.message)
    }
  }

  document.title = "Login | Video Processing Tool";

  return (
    <div className='login-outer'>
      <div className='header-outer'>
        <div className='icon-section'>
          <img src={Arrow} alt='Arrow' />
          <img src={File} alt='File' />
          <img src={Search} alt='Arrow' />
          <img src={Share} alt='Arrow' />
          <img src={Subtitle} alt='Arrow' />
          <img src={Video} alt='Arrow' />
          <img src={Wave} alt='Arrow' />
        </div>
        <div className='title'>
          Video App
        </div>
      </div>
      {showVerify ?
        <div className='verify-outer'>
          <h4>Email has been successfully sent to the email address you provided. Kindly check your email inbox for further details.</h4>
        </div>
        :
        <div className='login-form'>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
            action="#">
            <div className="mb-3">
              <Input
                name="email"
                className="form-control login-email"
                placeholder="Enter email"
                type="email"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.email || ""}
                invalid={
                  validation.touched.email && validation.errors.email ? true : false
                }
              />
              {validation.touched.email && validation.errors.email ? (
                <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
              ) : null}
            </div>
            <div className="mt-4 btn-outer">
              <Button disabled={loading ? true : false} className="btn login-btn " type="submit">
                {loading ? <Spinner size="sm" className='me-2'> Loading... </Spinner> : null}
                Get sign-in link
                <img src={LoginArrowIcon} alt='arrow' />
              </Button>
            </div>
          </Form>
        </div>
      }
    </div>
  )
}
export default Login